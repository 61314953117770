<template>
    <div class="page-header align-items-start">
        <span class="mask bg-white"></span>
        <div class="container" style="padding-left: 10px; padding-right: 10px;">
            <div class="row">
                <div class="notifications">
                <material-alert
                    v-if="error"
                    color="danger"
                    icon="ni ni-like-2 ni-lg"
                    dismissible
                >
                    {{ error }}
                </material-alert>
                </div>
                <div style="display: inline-flex;margin-top: 10px;gap: 10px;min-height: calc(100vh - 300px);">
                    <div class="col-lg-2 col-md-2 col-2" style="height: 100%; border: 1px solid #d5d6d8;text-align: center;padding-top: 10px; overflow-y: auto;">
                        <!-- <material-button
                            @click="handleAddModifierOptionGroup()"
                            style="width: 90%;margin-top: 5px;"
                            class="btn btn-sm btn-background"
                            >Add Option Group</material-button
                        > -->
                        <div v-for="(value, key) in modifierOptionGroupList" :key="key">
                            <div style="width: 100%;display: inline-flex;">
                                <div :style="getStyleCategory()" :class="item.id == value.id ? 'button-edit-modifier-group-active' : 'button-edit-modifier-group'">
                                    <div
                                        @click="handleEditModifierOptionGroup(value)"
                                        style="width: 95%"
                                    >
                                        <div class="d-flex align-items-center">
                                            <div>{{ value.name }}<span v-if="value.is_auto_created">-default</span> </div>
                                        </div>
                                    </div>
                                    <div
                                        class="dropdown"
                                        style="width: 5%; text-align: end"
                                    >
                                        <i
                                            class="dropdownMenuButton"
                                            :class="
                                                item.id == value.id
                                                ? 'fas fa-ellipsis-v text-white'
                                                : 'fas fa-ellipsis-v text-dark'
                                            "
                                            id="dropdownCategory1"
                                            data-bs-toggle="dropdown"
                                        ></i>
                                        <ul
                                            class="dropdown-menu"
                                            aria-labelledby="dropdownCategory1"
                                        >
                                            <!-- <li>
                                                <a
                                                    @click="handleAddModifierOptionGroup()"
                                                    class="dropdown-item"
                                                    href="#"
                                                >
                                                    Add
                                                </a>
                                            </li> -->
                                            <li>
                                                <a
                                                    @click="handleEditModifierOptionGroup(value)"
                                                    class="dropdown-item"
                                                    href="#"
                                                >
                                                    Update
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    @click="confirmDeleteModifierOptionGroup(value.id)"
                                                    class="dropdown-item"
                                                    href="#"
                                                >
                                                    Delete
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                
                                    
                                <!-- <div style="width: 90%;">
                                    <material-button
                                        @click="handleEditModifierOptionGroup(value)"
                                        :class="item.id == value.id ? 'btn btn-sm btn-background button-edit-modifier-group-active' : 'btn btn-sm btn-background button-edit-modifier-group'"
                                        >{{ value.name }} <i :class="item.id == value.id ? 'fas fa-pencil-alt' : 'fas fa-pencil-alt text-dark'" style="font-size: 10px;"></i></material-button
                                    >
                                </div>
                                <div style="width: 10%;;">
                                    <a
                                        class="
                                        btn btn-link
                                        text-danger text-gradient
                                        "
                                        href="javascript:;"
                                        @click="confirmDeleteModifierOptionGroup(value.id)"
                                        style="padding: 0px;margin-top: 15px"
                                    >
                                        <i
                                        class="far fa-trash-alt me-2"
                                        aria-hidden="true"
                                        ></i
                                        >
                                    </a>
                                </div> -->
                            </div>
                        </div>
                        <material-button
                            @click="handleAddModifierOptionGroup()"
                            class="btn btn-sm btn-background"
                            style="margin-top: 20px;margin-bottom: 20px;"
                        >
                            <i class="material-icons css-icon">addoutlined</i>
                            ADD
                        </material-button>
                    </div>
                    <div v-if="!loaderActive" class="col-lg-10 col-md-10 col-10" style="height: 100%; border: 1px solid #d5d6d8; padding: 20px; padding-top: 15px; overflow-y: auto;">
                        <h5 v-if="item.is_auto_created">
                            OPTION GROUP DETAIL: {{ item.name }}-default
                        </h5>
                        <h5 v-else>
                            <span v-if="!isEditing">
                                OPTION GROUP DETAIL: {{ item.name }}
                                <i v-if="item && item.id" class="material-icons" style="margin-top: 2.5px; margin-left: 3px;" @click="editItem">edit</i>
                            </span>
                            <span v-else style="display: flex;">
                                <material-input
                                    v-model="editedName"
                                    type="text"
                                    id="menuset_name"
                                    name="menuset_name"
                                    placeholder="Enter name"
                                    style="width: 150px;"
                                />
                                <material-button
                                    @click="saveItem"
                                    class="btn btn-sm btn-background"
                                    style="margin-left: 10px;"
                                >
                                    Save
                                </material-button>
                                <material-button
                                    @click="cancelEdit"
                                    class="btn btn-sm btn-background"
                                    style="margin-left: 10px;"
                                >
                                    Cancel
                                </material-button>
                            </span>
                        </h5>
                        <div class="row d-flex justify-content-center">
                            <ul id="general-tab" class="nav nav-tabs">
                            <li class="nav-item">
                                <a
                                id="nav_item_1"
                                class="nav-link"
                                :class="{ active: type == 1 }"
                                @click="changeTab(1)"
                                >General and Modifier Selection</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                id="nav_item_2"
                                class="nav-link"
                                :class="{ active: type == 2 }"
                                @click="changeTab(2)"
                                >Attached Item</a
                                >
                            </li>
                            </ul>
                        </div>
                        <div v-if="type == 1">
                            <div style="display: inline-flex;width: 100%;align-items: center;" v-if="item && item.id == 0">
                                <div class="col-lg-3 col-md-3 col-3">Name<span style="color: red;">*</span></div>
                                <div class="col-lg-9 col-md-9 col-9">
                                    <material-input
                                        v-model="item.name"
                                        type="text"
                                        id="menuset_name"
                                        name="menuset_name"
                                        placeholder="Enter name"
                                    />
                                </div>
                            </div>
                            <!-- <div style="display: inline-flex;width: 100%;align-items: center; margin-top: 5px;">
                                <div class="col-lg-3 col-md-3 col-3">Option Group POS Name</div>
                                <div class="col-lg-9 col-md-9 col-9">
                                    <material-input
                                        v-model="item.pos_name"
                                        type="text"
                                        id="menuset_name"
                                        name="menuset_name"
                                        placeholder="Enter name"
                                    />
                                </div>
                            </div>
                            <div style="display: inline-flex;width: 100%;align-items: center; margin-top: 5px;">
                                <div class="col-lg-3 col-md-3 col-3">Button color</div>
                                <div class="col-lg-3 col-md-3 col-3">
                                    <color-input v-model="item.button_color" position="right bottom"/>
                                </div>
                                <div class="col-lg-3 col-md-3 col-3">Button font color</div>
                                <div class="col-lg-3 col-md-3 col-3">
                                    <color-input v-model="item.button_font_color" position="left bottom"/>
                                </div>
                            </div> -->
                            <div style="width: 100%; margin-top: 10px; margin-bottom: 10px;"></div>
                            <div v-for="(value, key) in modifierGroupArray" :key="key" style="padding: 5px;margin-bottom: 10px;border: 1px solid #d5d6d8">
                                <div style="width: 25px;height: 25px;background: #EAEBED;position: absolute;right: 35px;align-items: center;justify-content: center;display: flex;">
                                    <div class="d-flex align-items-center">
                                    <i class="fa fa-ellipsis-v text-secondary cursor-pointer dropdownMenuButton"
                                        aria-hidden="true"
                                        id="dropdownMenuButton"
                                        data-bs-toggle="dropdown"
                                        style="margin-top: -3px;"
                                    ></i>
                                    <ul
                                        class="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                    >
                                        <li>
                                            <a
                                            class="dropdown-item"
                                            href="#"
                                            @click="
                                                moveUpDown(
                                                    value.id,
                                                    'up',
                                                    key
                                                )
                                            "
                                            >
                                            <i class="material-icons me-1"
                                                >arrow_upward</i
                                            >
                                            <span>Move Up</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                            class="dropdown-item"
                                            href="#"
                                            @click="
                                                moveUpDown(
                                                    value.id,
                                                    'down',
                                                    key
                                                )
                                            "
                                            >
                                            <i class="material-icons me-1"
                                                >arrow_downward</i
                                            >
                                            <span>Move Down</span>
                                            </a>
                                        </li>
                                    </ul>
                                  </div>
                                </div>
                                <div style="display: inline-flex;width: 100%;align-items: center; margin-top: 5px;">
                                    <div class="col-lg-3 col-md-3 col-3">Modifier Selection {{ key + 1 }}<span style="color: red;">*</span></div>
                                    <div class="col-lg-5 col-md-5 col-5">
                                        <treeselect
                                            :disabled="item.is_auto_created == 1 || item.is_auto_created == '1' ? true : false"
                                            @update:model-value="onchangeGroupOrSubgroup"
                                            show-count="true"
                                            v-model="value.applied_modifier_group_id"
                                            :multiple="false"
                                            :options="appliedDropdownData"
                                            :flat="true"
                                            :auto-deselect-ancestors="false"
                                            :auto-deselect-descendants="false"
                                            :auto-select-ancestors="false"
                                            :auto-select-descendants="false"
                                            >
                                            <template v-slot:option-label="{ node, labelClassName }">
                                                <label :class="labelClassName">
                                                {{ node.label }}
                                                </label>
                                            </template>
                                            <template v-slot:value-label="{ node }">
                                                <span class="custom-value-label">{{ checkStringType(node.id)+" "+node.label }}</span>
                                            </template>
                                        </treeselect>
                                    </div>
                                </div>
                                <div style="display: inline-flex;width: 100%;align-items: center; margin-top: 15px;">
                                    <div class="col-lg-3 col-md-3 col-3">Price list<span style="color: red;">*</span></div>
                                    <div class="col-lg-9 col-md-9 col-9">
                                        <Multiselect
                                            @change="onchangePriceList($event)"
                                            v-model="value.price_list_id"
                                            :options="priceList"
                                            :canClear="false"
                                        />
                                    </div>
                                </div>
                                <div style="display: inline-flex;width: 100%;align-items: center; margin-top: 15px;">
                                    <div class="col-lg-3 col-md-3 col-3">Item Max<span style="color: red;">*</span></div>
                                    <div class="col-lg-2 col-md-2 col-2">
                                        <material-input
                                            v-model="value.item_max_qty"
                                            type="text"
                                            placeholder="Enter"
                                        />
                                    </div>
                                    <!-- <div class="col-lg-1 col-md-1 col-1"></div> -->
                                    <div class="col-lg-2 col-md-2 col-2" style="text-align: right;padding-right: 20px;">Min</div>
                                    <div class="col-lg-1 col-md-1 col-1">
                                        <material-input
                                            v-model="value.min_qty"
                                            type="text"
                                            placeholder="Enter"
                                        />
                                    </div>
                                    <!-- <div class="col-lg-1 col-md-1 col-1"></div> -->
                                    <div class="col-lg-2 col-md-2 col-2" style="text-align: right;padding-right: 20px;">Max<span style="color: red;">*</span></div>
                                    <div class="col-lg-1 col-md-1 col-1">
                                        <material-input
                                            v-model="value.max_qty"
                                            type="text"
                                            placeholder="Enter"
                                        />
                                    </div>
                                </div>
                                <div style="display: inline-flex;width: 100%;align-items: center; margin-top: 15px;">
                                    <div class="col-lg-3 col-md-3 col-3">Dining Option<span style="color: red;">*</span></div>
                                    <div class="col-lg-2 col-md-2 col-2">
                                        <Multiselect
                                            v-model="value.dining_option"
                                            :options="diningOptionDataList"
                                            :canClear="false"
                                        />
                                    </div>
                                    <!-- <div class="col-lg-1 col-md-1 col-1"></div> -->
                                    <div class="col-lg-2 col-md-2 col-2" style="text-align: right;padding-right: 20px;">Remark</div>
                                    <div class="col-lg-1 col-md-1 col-1" style="text-align: center;">
                                        <material-checkbox
                                            :checked="value.is_remark"
                                            v-model="value.is_remark"
                                            name="remark"
                                            class="align-items-center"
                                        ></material-checkbox>
                                    </div>
                                    <!-- <div class="col-lg-1 col-md-1 col-1"></div> -->
                                    <div class="col-lg-2 col-md-2 col-2" style="text-align: right;padding-right: 20px;">Required?</div>
                                    <div class="col-lg-1 col-md-1 col-1" style="text-align: center;">
                                        <material-checkbox
                                            :checked="value.is_required"
                                            v-model="value.is_required"
                                            name="required"
                                            class="align-items-center"
                                        ></material-checkbox>
                                    </div>
                                </div>
                                <div style="margin-top: 20px" v-if="value.applied_modifier_group_id">
                                    <h5>Modifiers & Prices</h5>

                                    <div style="margin-top: 10px;" v-for="(value1, key) in appliedPriceList" :key="key">
                                        <div v-if="value.applied_modifier_group_id.includes(value1.id)" style="padding: 5px;border: 1px solid #d5d6d8;margin-top: 10px;width: 100%;">
                                            <!-- <div class="col-lg-12 col-md-12 col-12">{{ value1.title }}</div> -->
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="text-left font-weight-bolder w-5" style="padding: 0.5rem 0.5rem !important;">
                                                        Code
                                                        </th>
                                                        <th class="text-left font-weight-bolder w-20" style="padding: 0.5rem 0.5rem !important;">
                                                        Name
                                                        </th>
                                                        <th class="text-left font-weight-bolder w-5" style="padding: 0.5rem 0.5rem !important;">
                                                        Image
                                                        </th>
                                                        <th class="text-left font-weight-bolder w-5" style="padding: 0.5rem 0.5rem !important;">
                                                        Price
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="value1.items && value1.items.length">
                                                    <tr v-for="(value, key) in value1.items" :key="key">
                                                        <td
                                                            class="text-left text-xss w-5"
                                                            >
                                                            {{ value.item_no_format }} {{ value.variation_no ? "( "+value.variation_no+" )" : "" }} 
                                                        </td>
                                                        <td
                                                            class="text-left text-xss w-20"
                                                            >
                                                            {{ value.item_name_format }}
                                                        </td>
                                                        <td
                                                            class="text-left text-xss w-5"
                                                            >
                                                            <img :src="value.image" alt="Image" width="70" height="70">
                                                        </td>
                                                        <td
                                                            class="text-left text-xss w-5"
                                                            >
                                                            {{ value.item_price_format }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                    <td colspan="5" class="text-center">No item available</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-4" style="display: inline-flex;margin-top: 20px;" v-if="!item.is_auto_created">
                                    <material-button
                                        v-if="key == 0"
                                        @click="addMoreModifierGroup()"
                                        class="btn btn-sm btn-background"
                                        >
                                            <i class="material-icons css-icon">addoutlined</i>
                                            ADD SELECTION
                                        </material-button
                                    >
                                    <material-button
                                        v-else
                                        @click="removeMoreModifierGroup(key)"
                                        class="btn btn-sm btn-background"
                                        >
                                            <i class="material-icons css-icon">removeoutlined</i>
                                            REMOVE SELECTION
                                        </material-button
                                    >
                                </div>
                            </div>
                        </div>
                        
                        <div style="margin-top: 20px" v-if="type == 2">
                            <div style="display: inline-flex;align-items: center;">
                                <h5 style="margin-top: 11px;margin-right: 10px;">ATTACHED TO ITEM</h5>
                                <material-button
                                    @click="addItem()"
                                    class="btn btn-sm btn-background"
                                    >
                                        Add Items
                                        {{ dataPreiewItem && dataPreiewItem.itemsId && dataPreiewItem.itemsId.length > 0 ? "("+dataPreiewItem.itemsId.length+" item selected)" : "" }}
                                    </material-button
                                >
                            </div>

                            <table class="table">
                                <thead>
                                <tr>
                                    <th class="text-left font-weight-bolder w-5" style="padding: 0.75rem 0.5rem !important;">
                                        No
                                    </th>
                                    <th class="text-left font-weight-bolder w-60" style="padding: 0.75rem 0.5rem !important;">
                                    Item
                                    </th>
                                    <th class="text-left font-weight-bolder w-15" style="padding: 0.75rem 0.5rem !important;">
                                    Price
                                    </th>
                                    <!-- <th class="text-center font-weight-bolder w-20">
                                    Group
                                    </th> -->
                                    <th class="text-left font-weight-bolder w-10" style="padding: 0.75rem 0.5rem !important;">
                                    <!-- Item image -->
                                    </th>
                                    <th class="text-left font-weight-bolder w-10" style="padding: 0.75rem 0.5rem !important;">
                                    <!-- Delete -->
                                    </th>
                                </tr>
                                </thead>
                                <tbody v-if="attachedToItem.length">
                                    <tr v-for="(value, key) in attachedToItem" :key="key">
                                        <td
                                            class="text-left text-xss w-25"
                                            >
                                            {{ value.item_no }}
                                        </td>
                                        <td
                                            class="text-left text-xss w-25"
                                            style="color: black;"
                                            >
                                            {{ value.item_name }} {{ value.variation_name ? "("+value.variation_name+")" : "" }} 
                                        </td>
                                        <td
                                            class="text-left text-xss w-25"
                                            >
                                            {{ value.item_price }}
                                        </td>
                                        <!-- <td
                                            class="text-center text-xss w-25"
                                            >
                                            {{ value.group_name }}
                                        </td> -->
                                        <th class="text-left text-xss w-25">
                                            <img :src="value.item_image" alt="Image" width="70" height="70">
                                        </th>
                                        <th 
                                            class="text-left text-xss w-25"
                                            >
                                            <a
                                                class="
                                                btn btn-link
                                                text-danger text-gradient
                                                px-3
                                                mb-0
                                                "
                                                href="javascript:;"
                                                @click="confirmDelete(value.id)"
                                            >
                                                <i
                                                class="far fa-trash-alt me-2"
                                                aria-hidden="true"
                                                style="margin-top: 20px;"
                                                ></i
                                                >
                                            </a>
                                        </th>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                    <td colspan="5" class="text-center">No item available</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="container" style="text-align: center;">
                            <material-button
                                @click="handleSaveAll()"
                                style="width: 10%;"
                                class="btn btn-sm btn-background"
                                >Save</material-button
                            >
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <ConfirmModal
            :visible="isModalVisible"
            message="There are some changes on this page."
            @confirm="handleUpdate"
            @cancel="handleDiscard"
            @update:visible="isModalVisible = $event"
        />
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
            <material-snackbar
                v-if="snackbar === 'danger' || snackbar === 'success'"
                title=""
                date=""
                :description="message"
                :icon="{ component: 'campaign', color: 'white' }"
                :color="snackbar"
                :close-handler="closeSnackbar"
            />
        </div>
        <material-loading :active="loaderActive" />
    </div>
    <div class="container" style="margin-bottom: 100px; margin-top: 20px;text-align: center;"></div>
    
</template>
  
<script>
    import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
    import "bootstrap/dist/js/bootstrap.bundle";
    import MaterialLoading from "@/components/MaterialLoading.vue";
    import "bootstrap/js/dist/modal";
    import MaterialButton from "@/components/MaterialButton.vue";
    import "@vuepic/vue-datepicker/dist/main.css";
    //   import ModalConfirm from "@/components/ModalConfirm.vue";
    import "@vueform/multiselect/themes/default.css";
    import MaterialInput from "@/components/MaterialInput.vue";
    //   import $ from "jquery";
    import TokenService from "../../services/token.service";
    //   import MaterialRadio from "@/components/MaterialRadio.vue";
    // import ColorInput from "vue-color-input";
    import Multiselect from "@vueform/multiselect";
    import ModifierOptionGroupService from '../../services/product/modifierOptionGroup';
    import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
    import Treeselect from 'vue3-treeselect'
    import 'vue3-treeselect/dist/vue3-treeselect.css'
    import $ from "jquery";
    import ConfirmModal from "@/components/ConfirmModal.vue";
  
  export default {
    name: "pos-staff",
    components: {
        MaterialSnackbar,
        MaterialLoading,
        MaterialButton,
        //   ModalConfirm,
        MaterialInput,
        //   MaterialRadio,
        // ColorInput,
        Multiselect,
        MaterialCheckbox,
        Treeselect,
        ConfirmModal
    },
    props: {
        menu_set_id: {
            type: Number,
            default: 0
        },
        reloadModifierOptionGroup: {
            type: String,
            required: false
        },
        dataPreiewItemSelected: {
            type: Array,
            required: false
        },
        date_time_now: {
            type: Object,
            required: false
        },
    },
    watch: {
        reloadModifierOptionGroup(newData, oldData) {
            console.log(oldData);
            console.log(newData);
            if(newData == "Delete Success") {
                // this.getData();
                this.getAttachedToItem();
            } else {
                this.getData();
                this.getAttachedToItem();
            }
        },
        dataPreiewItemSelected(newData, oldData) {
            console.log(oldData);
            console.log(newData);
            this.dataPreiewItem = newData;
        },
        date_time_now() {
            // this.getData();
        },
        'modifierGroupArray': {
            handler(newVal) {
                // if(this.isSorting == false) {
                    if(this.isSorting == true) {
                        setTimeout(() => {
                            this.isSorting = false;
                        }, 1000);
                    } else {
                        this.isUpdating = this.isUpdating + 1;
                    }
                    newVal.forEach((value, index) => {
                        if (!Array.isArray(value.applied_modifier_group_id)) {
                            if(value.applied_modifier_group_id) {
                                this.modifierGroupArray[index].applied_modifier_group_id = [value.applied_modifier_group_id];
                            }
                        }
                    });
                // }
            },
            deep: true,
        },
        'item': {
            handler(newVal) {
                console.log(newVal);
                this.isUpdating = this.isUpdating + 1;

            },
            deep: true,
        },
        'editedName': {
            handler(newVal) {
                console.log(newVal);
                this.isUpdating = this.isUpdating + 1;

            },
            deep: true,
        },
    },
    data() {
        return {
            message: "",
            snackbar: null,
            loaderActive: false,
            modifierOptionGroupList: [],
            priceList: [],
            token: TokenService.getLocalAccessToken(),
            merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),

            item: {
                id: 0,
                name: '',
                pos_name: '',
                button_color: '#000000',
                button_font_color: '#ff0000',
                is_auto_created: 0
            },

            diningOptionDataList: [
                { 'label': 'All', 'value': 1 },
                { 'label': 'Dine in', 'value': 2 },
                { 'label': 'Takeaway', 'value': 3 }
            ],

            appliedDropdownData: [],
            appliedPriceList: [],
            attachedToItem: [],
            keyReload: 'key-reload',
            dataPreiewItem: [],
            modifierGroupArray: [
                {
                    applied_modifier_group_id: null,
                    price_list_id: "",
                    item_max_qty: "",
                    min_qty: "",
                    max_qty: "",
                    dining_option: 1,
                    is_remark: 0,
                    is_required: 0,
                    id: 0
                }
            ],
            isUpdating: 0,
            type: 1,
            isEditing: false,
            editedName: '',
            isModalVisible: false,
            itemMove: null,
            firstLoading: false,
            isSorting: false
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.showLoader();
            var data = {
                set_id: this.menu_set_id
            }
            ModifierOptionGroupService.getList(data).then(
            (response) => {
                if (response.data.result.isSuccess) {
                    this.modifierOptionGroupList = response.data.data.item;
                    console.log("response.data.data.price_list", response.data.data.price_list)
                    this.priceList = response.data.data.price_list;
                    // const newArrayElement = {
                    //     "price_key": "price_0",
                    //     "name": "All Price List",
                    //     "description": "",
                    //     "is_display": 1,
                    //     "price": 0,
                    //     "label": "All Price List",
                    //     "value": "price_0"
                    // };
                    // this.priceList.unshift(newArrayElement);
                    this.appliedDropdownData = response.data.data.categories;
                    // console.log(this.appliedDropdownData);
                    // this.appliedDropdownData.unshift({ 
                    //     label: 'SELECT MODIFIER GROUP',
                    //     id: '0',
                    //     id_query: 0,
                    //     label_converted: 'SELECT MODIFIER GROUP'
                    // });

                    // this.resetParam(); 
                    if (response.data.data.item && response.data.data.item.length > 0 && this.firstLoading == false) {
                        this.handleEditModifierOptionGroup(response.data.data.item[0]);
                    } else {
                        if(response.data.data.item && response.data.data.item.length > 0) {
                            const result = response.data.data.item.find(item => item.id === this.item.id);
                            this.closeAllDropdowns();
                            this.showLoader();
                            this.item.id = result.id;
                            this.item.name = result.name;
                            this.item.pos_name = result.pos_name;
                            this.item.button_color = result.button_color;
                            this.item.button_font_color = result.button_font_color;
                            this.item.dataPreiewItem = [];
                            this.modifierGroupArray = result.modifierGroupArray;
                            this.item.is_auto_created = result.is_auto_created;
                            setTimeout(() => {
                                this.hideLoader();
                                this.getAppliedPrice();
                                this.getAttachedToItem();
                            }, 1000);
                            this.isUpdating = 0;
                            this.isSorting = false;
                        }
                    }
                    this.isUpdating = 0;
                    this.isSorting = false;

                    if(this.itemMove) {
                        this.isUpdating = 0;
                        this.isSorting = false;
                        $(".btn-close-confirm-unsave-modal").click();
                        this.handleEditModifierOptionGroup(this.itemMove);
                        this.itemMove = null;
                    }

                    this.firstLoading = true;
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
            },
            (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.setTimeOutNotification();
            }
            );
            this.hideLoader();
        },

        handleEditModifierOptionGroup(item) {
            if (this.isUpdating > 2) {
                this.itemMove = item;
                // alert("There is some changes to this page. please choose")
                // this.isModalVisible = true;
                $(".btn-confirm-modal-unsave").click();
            } else {
                this.closeAllDropdowns();
                console.log(item);
                this.showLoader();
                this.item.id = item.id;
                this.item.name = item.name;
                this.item.pos_name = item.pos_name;
                this.item.button_color = item.button_color;
                this.item.button_font_color = item.button_font_color;
                this.item.dataPreiewItem = [];
                this.modifierGroupArray = item.modifierGroupArray;
                this.item.is_auto_created = item.is_auto_created;
                setTimeout(() => {
                    this.hideLoader();
                    this.getAppliedPrice();
                    this.getAttachedToItem();
                }, 1000);
                this.isUpdating = 0;
                this.isSorting = false;
            }
        },

        handleAddModifierOptionGroup() {
            this.isUpdating = 0;
            this.isSorting = false;
            this.closeAllDropdowns();
            this.resetParam();
        },

        resetParam() {
            this.showLoader();
            this.item.id = 0,
            this.item.name = '',
            this.item.pos_name = '',
            this.item.button_color = '#000000',
            this.item.button_font_color = '#ff0000',
            this.appliedPriceList = [];
            this.attachedToItem = [];
            this.dataPreiewItem = [];
            this.modifierGroupArray = [
                {
                    applied_modifier_group_id: null,
                    price_list_id: "",
                    item_max_qty: "",
                    min_qty: "",
                    max_qty: "",
                    dining_option: 1,
                    is_remark: 0,
                    is_required: 0
                }
            ];
            this.item.is_auto_created = 0;
            setTimeout(() => {
                this.hideLoader();
            }, 1000);
        },

        handleSaveAll() {
            var checkError = false;
            this.showLoader();
            var data = {
                name: this.item.name,
                pos_name: this.item.name,
                button_color: this.item.button_color,
                button_font_color: this.item.button_font_color,
                set_id: this.menu_set_id,
                modifierGroupArray: this.modifierGroupArray
            };

            if (!this.item.name) {
                checkError = true;
                this.snackbar = "danger";
                this.message = "The Name is required.";
                this.setTimeOutNotification();
                this.hideLoader();
                return;
            }

            // if (!this.item.pos_name) {
            //     checkError = true;
            //     this.snackbar = "danger";
            //     this.message = "The POS Name is required.";
            //     this.setTimeOutNotification();
            //     this.hideLoader();
            //     return;
            // }

            // if (!this.item.button_color) {
            //     checkError = true;
            //     this.snackbar = "danger";
            //     this.message = "The Button color is required.";
            //     this.setTimeOutNotification();
            //     this.hideLoader();
            //     return;
            // }

            // if (!this.item.button_font_color) {
            //     checkError = true;
            //     this.snackbar = "danger";
            //     this.message = "The Button font color is required.";
            //     this.setTimeOutNotification();
            //     this.hideLoader();
            //     return;
            // }

            data.modifierGroupArray.forEach((modifierGroup) => {
                // if(!modifierGroup || !modifierGroup.applied_modifier_group_id || (modifierGroup && modifierGroup.applied_modifier_group_id && modifierGroup.applied_modifier_group_id.length == 0)) {
                if(!modifierGroup || !modifierGroup.applied_modifier_group_id || (modifierGroup && modifierGroup.applied_modifier_group_id == 0)) {
                    checkError = true;
                    this.snackbar = "danger";
                    this.message = "The Applied Modifier Group is required.";
                    this.setTimeOutNotification();
                    this.hideLoader();
                    return;
                }
                if (!modifierGroup.price_list_id) {
                    checkError = true;
                    this.snackbar = "danger";
                    this.message = "The Price list is required.";
                    this.setTimeOutNotification();
                    this.hideLoader();
                    return;
                }
                if (modifierGroup.item_max_qty === "") {
                    checkError = true;
                    this.snackbar = "danger";
                    this.message = "The Item Max is required.";
                    this.setTimeOutNotification();
                    this.hideLoader();
                    return;
                }
                // if (!modifierGroup.min_qty) {
                //     checkError = true;
                //     this.snackbar = "danger";
                //     this.message = "The Min is required.";
                //     this.setTimeOutNotification();
                //     this.hideLoader();
                //     return;
                // }
                if (!modifierGroup.max_qty) {
                    checkError = true;
                    this.snackbar = "danger";
                    this.message = "The Max is required.";
                    this.setTimeOutNotification();
                    this.hideLoader();
                    return;
                }
                if (!modifierGroup.dining_option) {
                    checkError = true;
                    this.snackbar = "danger";
                    this.message = "The Dining Option is required.";
                    this.setTimeOutNotification();
                    this.hideLoader();
                    return;
                }
            });
            // console.log(data);
            if(checkError === false) {
                if(this.item.id && this.item.id > 0) {
                    // update
                    ModifierOptionGroupService.update(this.item.id, data).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.snackbar = "success";
                            this.message = "Updated Modifier Option Group successfully!";
                            this.setTimeOutNotification();
                            // this.resetParam();
                            this.getData();
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.setTimeOutNotification();
                    }
                    );
                } else {
                    // add
                    ModifierOptionGroupService.store(data).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.snackbar = "success";
                            this.message = "Added Modifier Option Group successfully!";
                            this.setTimeOutNotification();
                            this.dataPreiewItem.modifier_option_group_id = response.data.data.id;
                            this.addAttachedToItem(this.dataPreiewItem);
                            this.getData();
                            this.resetParam();

                            const result = response.data.data;
                            this.closeAllDropdowns();
                            this.showLoader();
                            this.item.id = result.id;
                            this.item.name = result.name;
                            this.item.pos_name = result.pos_name;
                            this.item.button_color = result.button_color;
                            this.item.button_font_color = result.button_font_color;
                            this.item.dataPreiewItem = [];
                            this.modifierGroupArray = result.modifierGroupArray;
                            this.item.is_auto_created = result.is_auto_created;
                            setTimeout(() => {
                                this.hideLoader();
                                this.getAppliedPrice();
                                this.getAttachedToItem();
                            }, 1000);
                            this.isUpdating = 0;
                            this.isSorting = false;
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.setTimeOutNotification();
                    }
                    );
                }
            }
            this.hideLoader();
        },

        closeSnackbar() {
            this.snackbar = null;
        },
  
        setTimeOutNotification() {
            setTimeout(() => {
            this.snackbar = null;
            }, 3000);
        },
  
        showLoader() {
            this.loaderActive = true;
        },
  
        hideLoader() {
            this.loaderActive = false;
        },

        checkStringType(str) {
            if (str.includes("sg")) {
                return "Sub Group : ";
            } else if (str.includes("g")) {
                return "Group : ";
            } else if (str.includes("c")) {
                return "Category : ";
            } else {
                return '';
            }
        },

        onchangeGroupOrSubgroup(value) {
            if(value != '0') {
                this.getAppliedPrice()
            } else {
                this.appliedPriceList = [];
            }
        },

        onchangePriceList($event) {
            console.log($event);
            this.getAppliedPrice()
        },

        getAppliedPrice() {
            this.showLoader();
            var data = {
                modifierGroupArray: this.modifierGroupArray
            }
            ModifierOptionGroupService.getAppliedPriceList(data).then(
            (response) => {
                if (response.data.result.isSuccess) {
                    console.log("response.data.data", response.data.data);
                    this.appliedPriceList = response.data.data;
                } else {
                    this.appliedPriceList = [];
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
            },
            (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.setTimeOutNotification();
            }
            );
            this.hideLoader();
        },

        closeModal() {
            $(".btn-close-modal").click();
        },

        addItem() {
            var data = {
                modifier_option_group_id: this.item.id,
                attachedToItem: this.attachedToItem,
                itemObject: this.item
            }
            this.$emit('child-event', data);
        },

        getAttachedToItem() {
            if(this.item.id > 0) {
                this.showLoader();
                var data = {
                    modifier_option_group_id: this.item.id
                }
                ModifierOptionGroupService.getAttachedToItem(data).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.attachedToItem = response.data.data;
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.setTimeOutNotification();
                }
                );
                this.hideLoader();
            }
        },

        confirmDelete(itemId) {
            this.$emit('delete-item', itemId);
        },

        addAttachedToItem(data) {
            console.log("dataAddAttachedToItem", data)
            if(data.modifier_option_group_id > 0 && (data.itemsId && data.itemsId.length > 0)) {
                ModifierOptionGroupService.attachedToItem(data).then(
                    (response) => {
                    if (response.data.result.isSuccess) {
                        // alert("success");
                        // $(".btn-close-modal").click();
                        // this.$emit("reload-list-attached-to-item", "Add success");
                    }
                    this.hideLoader();
                },
                    (error) => {
                    this.message = error;
                    this.hideLoader();
                    }
                );
            }
        },

        confirmDeleteModifierOptionGroup(itemId) {
            this.closeAllDropdowns();
            this.$emit('delete-modifier-option-group', itemId);
        },

        addMoreModifierGroup() {
            const newModifierGroup = {
                applied_modifier_group_id: null,
                price_list_id: "",
                item_max_qty: "",
                min_qty: "",
                max_qty: "",
                dining_option: 1,
                is_remark: 0,
                is_required: 0
            };

            this.modifierGroupArray.push(newModifierGroup);
        },

        removeMoreModifierGroup(index) {
            if (index >= 0 && index < this.modifierGroupArray.length) {
                this.showLoader();
                this.modifierGroupArray.splice(index, 1);
                setTimeout(() => {
                    this.hideLoader();
                    this.getAppliedPrice()
                }, 1000);
            }
        },

        getStyleCategory() {
            return {
                justifyContent: "space-between",
                alignItems: "center",
                display: "flex",
                width: "100%",
                border: "#ccc",
                // background: isHighlighted ? "#133067" : "white",
                // color: isHighlighted ? "white" : "black",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
                // borderTop: index == 0 ? "1px solid #ccc" : "unset",
                borderBottom: "1px solid #ccc",
                // borderLeft: "1px solid #ccc",
                // borderRight: "1px solid #ccc",
                cursor: "pointer",
            };
        },

        closeAllDropdowns() {
            const dropdownMenus = document.querySelectorAll("ul.dropdown-menu.show");
            dropdownMenus.forEach((menu) => {
                menu.classList.remove("show");
                menu.classList.add("hide");
            });
        },

        async changeTab(type) {
            this.type = type;
        },

        editItem() {
            this.isEditing = true;
            this.editedName = this.item.name;
        },
        saveItem() {
            var data = {
                id: this.item.id,
                name: this.editedName
            }
            ModifierOptionGroupService.updateNameModifierOptionGroup(data).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        
                        var item = response.data.data;
                        this.showLoader();
                        var data1 = {
                            set_id: this.menu_set_id
                        }
                        ModifierOptionGroupService.getList(data1).then(
                        (response) => {
                            if (response.data.result.isSuccess) {
                                this.modifierOptionGroupList = response.data.data.item;
                                this.item.id = item.id;
                                this.item.name = item.name;
                                this.item.pos_name = item.pos_name;
                                this.item.button_color = item.button_color;
                                this.item.button_font_color = item.button_font_color;
                                this.isUpdating = 0;
                                this.isSorting = false;
                                // this.item.dataPreiewItem = [];
                                // this.modifierGroupArray = item.modifierGroupArray;
                                // this.item.is_auto_created = item.is_auto_created;
                                // this.item.name = this.editedName;
                                this.isEditing = false;

                                this.snackbar = "success";
                                this.message = "Updated successfully!";
                                this.setTimeOutNotification();
                            } else {
                                this.snackbar = "danger";
                                this.message = response.data.result.message;
                                this.setTimeOutNotification();
                            }
                            this.hideLoader();
                        },
                        (error) => {
                            this.snackbar = "danger";
                            this.message = error;
                            this.setTimeOutNotification();
                            this.hideLoader();
                        }
                        );
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.setTimeOutNotification();
                }
            );
        },
        cancelEdit() {
            this.isUpdating = 0;
            this.isSorting = false;
            this.isEditing = false;
        },
        handleUpdate() {
            // Handle the case where user chose to UPDATE
            // this.handleSaveAll();

            $(".btn-close-confirm-unsave-modal").click();
        },
        handleDiscard() {
            this.getData();
            this.closeAllDropdowns();
            // console.log(item);
            this.showLoader();
            if(this.itemMove) {
                this.item.id = this.itemMove.id;
                this.item.name = this.itemMove.name;
                this.item.pos_name = this.itemMove.pos_name;
                this.item.button_color = this.itemMove.button_color;
                this.item.button_font_color = this.itemMove.button_font_color;
                this.modifierGroupArray = this.itemMove.modifierGroupArray;
                this.item.is_auto_created = this.itemMove.is_auto_created;
            }
            this.item.dataPreiewItem = [];
            setTimeout(() => {
                this.hideLoader();
                this.getAppliedPrice();
                this.getAttachedToItem();
            }, 1000);
            this.isUpdating = 0;
            this.isSorting = false;
            this.itemMove = null;
            $(".btn-close-confirm-unsave-modal").click();
        },
        moveUpDown(
            categoryItemId,
            type,
            itemIndex,
            // isCategory = false,
            // categoryIndex = 0
            ) {
            this.showLoader();
            if (type == "up" && itemIndex == 0) {
                this.hideLoader();
                return;
            }

            var dataForm = {
                id: categoryItemId,
                type: type,
            };

            ModifierOptionGroupService.moveUpDown(dataForm).then(
                (response) => {
                if (response.data.result.isSuccess) {
                    let newItemIndex = itemIndex;
                    if (type == "up") {
                        newItemIndex -= 1;
                    } else {
                        newItemIndex += 1;
                    }

                    let itemChange = this.modifierGroupArray[itemIndex];
                    this.modifierGroupArray.splice(itemIndex, 1);
                    this.modifierGroupArray.splice(
                        newItemIndex,
                        0,
                        itemChange
                    );
                    this.isSorting = true;
                    this.hideLoader();
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                this.hideLoader();
                },
                (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                this.setTimeOutNotification();
                }
            );
            },

    },
  };
  </script>
  <style scoped>
    .button-edit-modifier-group {
        width: 90%;margin-top: 5px;border: 0px !important;border-bottom: 1px solid #d5d6d8 !important;border-radius: 0px;text-align: left;
        text-transform: none;
    }
    .button-edit-modifier-group-active {
        width: 90%;margin-top: 5px;border: 0px !important;border-bottom: 1px solid #d5d6d8 !important;border-radius: 0px;text-align: left;
        background-color: #133067 !important;
        color: white !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        text-transform: none;
    }
    .table tbody tr:last-child td {
        border-bottom-width: 1px;
    }
    .css-icon {
        position: absolute;
        left: 17px !important;
        font-size: 18px !important;
        top: 7px !important;
    }
    .dropdown-menu {
        border: 1px solid #d5d6d8;
        min-width: 9rem;
        inset: 0px 0px auto auto !important;
    }
    .dropdownMenuButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 30px;
    }
    .dropdownMenuButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 30px;
    }

    .fa-ellipsis-v:before {
        margin-top: 6px;
    }

    .dropdown-item {
        display: flex;
        align-items: center;
    }
  </style>
  