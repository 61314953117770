<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-confirm-modal-unsave"
      data-bs-toggle="modal"
      data-bs-target="#modalConfirm"
    ></button>
    <div
      class="modal fade"
      id="modalConfirm"
      tabindex="-1"
      aria-labelledby="modalConfirmLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-header" style="display: none">
          <button type="button" class="close btn-close-confirm-unsave-modal" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-content">
          <div class="modal-body">{{ message }}</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary btn-close-modal btn-sm"
              @click="cancel"
            >
              DISCARD
            </button>
            <button
              type="button"
              class="btn btn-success btn-background btn-sm"
              @click="confirm"
            >
              CONTINUE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    message: String
  },
  emits: ['confirm', 'cancel'],
  methods: {
    confirm() {
      this.$emit('confirm');
      this.close();
    },
    cancel() {
      this.$emit('cancel');
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    }
  }
}
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
button {
  margin: 5px;
}
</style>
