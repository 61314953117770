<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalCreateModalMenuItem"
      id="show-modal-select-item"
    ></material-button>
    <material-button
      class="d-none"
      data-bs-dismiss="modal"
      data-bs-target="#modalCreateModalMenuItem"
      id="hide-modal-select-item"
    ></material-button>
    <div
      class="modal fade"
      id="modalCreateModalMenuItem"
      tabindex="-1"
      aria-hidden="true"
      ref="createModalMenuItem"
      style="z-index: 9999"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="width: unset">
          <div class="modal-body" style="overflow-y: unset">
            <div class="d-flex">
              <h5 class="modal-title" style="width: 95.6667%; cursor: pointer">
                Choose an option
              </h5>
              <i
                style="cursor: pointer"
                class="material-icons btn-close-modal justify-content-end"
                data-bs-dismiss="modal"
                aria-label="Close"
                >clear</i
              >
            </div>
            <div class="d-flex">
              <div class="content">
                <div class="mt-content" @click="openModalCreateItem()">
                  <h5>Create New Item</h5>
                  <i class="material-icons css-icon-menubook">edit</i>
                </div>
              </div>
              <!-- <div class="content">
                <div
                  class="mt-content"
                  @click="openModalCreateCombo()"
                >
                  <h5>Create New Combo</h5>
                  <i class="material-icons css-icon-menubook">edit</i>
                </div>
              </div> -->
              <div class="content">
                <div class="mt-content" @click="openSelectExistingItem()">
                  <h5>Select Existing Item</h5>
                  <i class="material-icons css-icon">credit_score</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalAddExistingItem
    :categoryId="categoryId"
    :groupId="groupId"
    :subGroupId="subGroupId"
    :keyReload="keyReloadModalAddExistingItem"
    @reload-list-category="reloadListCategory"
  ></ModalAddExistingItem>
  <ModalSelectItem
    :setId="setId"
    :categoryId="categoryId"
    :groupId="groupId"
    :subGroupId="subGroupId"
    :keyReload="keyReloadModalSelectItem"
    @reload-list-category="reloadListCategory"
    :isModifiderOptionGroup="isModifiderOptionGroup"
    :modifier_option_group_id="modifier_option_group_id"
    @reload-list-attached-to-item="reloadListAttachedToItem"
    @add-preiew-item-selected="addPreiewItemSelected"
    :date_time_now="date_time_now"
    :attachedToItemModifierOptionGroup="attachedToItemModifierOptionGroup"
    :itemByGroupOrSubGroupData="itemByGroupOrSubGroupData"
  ></ModalSelectItem>
  <ModalSelectItemForModiferOptionGroup
    :setId="setId"
    :categoryId="categoryId"
    :groupId="groupId"
    :subGroupId="subGroupId"
    :keyReload="keyReload"
    @reload-list-category="reloadListCategory"
    :isModifiderOptionGroup="isModifiderOptionGroup"
    :modifier_option_group_id="modifier_option_group_id"
    @reload-list-attached-to-item="reloadListAttachedToItem"
    @add-preiew-item-selected="addPreiewItemSelected"
    :date_time_now="date_time_now"
    :attachedToItemModifierOptionGroup="attachedToItemModifierOptionGroup"
    :itemByGroupOrSubGroupData="itemByGroupOrSubGroupData"
    :itemObject="itemObject"
  ></ModalSelectItemForModiferOptionGroup>
</template>

<script>
import $ from "jquery";
import ModalSelectItem from "@/components/ModalSelectItem.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import ModalSelectItemForModiferOptionGroup from "@/components/ModalSelectItemForModiferOptionGroup.vue";
import ModalAddExistingItem from "@/components/ModalAddExistingItem.vue";

export default {
  name: "create-menu-item",
  components: {
    ModalSelectItem,
    MaterialButton,
    ModalSelectItemForModiferOptionGroup,
    ModalAddExistingItem,
  },
  props: {
    type: {
      type: Number,
      required: true,
    },
    setId: {
      type: Number,
      required: true,
    },
    categoryId: {
      type: Number,
      required: true,
    },
    groupId: {
      type: Number,
      default: 0,
    },
    subGroupId: {
      type: Number,
      default: 0,
    },
    keyReload: {
      type: String,
      required: true,
    },
    isModifiderOptionGroup: {
      type: Boolean,
      required: false,
    },
    modifier_option_group_id: {
      type: Number,
      required: false,
    },
    date_time_now: {
      type: Object,
      required: false,
    },
    attachedToItemModifierOptionGroup: {
      type: Object,
      required: false,
    },
    itemByGroupOrSubGroupData: {
      type: Object,
      required: false,
    },
    itemObject: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      keyReloadModalAddExistingItem: "keyReloadModalAddExistingItem",
      keyReloadModalSelectItem: "keyReloadModalSelectItem",
    };
  },
  methods: {
    openSelectExistingItem() {
      $("#hide-modal-select-item").click();
      if (this.type == 1) { // Main
        this.keyReloadModalAddExistingItem = Math.random();
        $("#show-modal-add-existing-item").click();
      } else {
        this.keyReloadModalSelectItem = Math.random();
        $(".btn-select-item-modal").click();
      }
    },

    openModalCreateItem() {
      $("#hide-modal-select-item").click();
      this.$emit("create-new-item");
    },

    openModalCreateCombo() {
      $("#hide-modal-select-item").click();
      this.$emit("create-new-combo");
    },

    reloadListCategory(mess) {
      this.$emit("reload-list-category", mess);
    },

    reloadListAttachedToItem(mess) {
      this.$emit("reload-list-attached-to-item", mess);
    },

    addPreiewItemSelected(data) {
      this.$emit("add-preiew-item-selected", data);
    },
  },
};
</script>

<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}
.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}
.css-icon {
  font-size: 50px !important;
}
.css-icon-menubook {
  position: relative;
  font-size: 50px !important;
}
.font-18 {
  font-size: 18px;
}
.mt-content {
  margin-top: 5rem;
}
</style>
