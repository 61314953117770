<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-select-item-modal"
      data-bs-toggle="modal"
      data-bs-target="#modalSelectItem"
    ></button>
    <div
      class="modal fade"
      id="modalSelectItem"
      tabindex="-1"
      aria-hidden="true"
      style="z-index: 9999"
    >
      <div class="modal-dialog modal-dialog-centered modal-width">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Attach Items</h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body d-flex" style="overflow-y: unset">
            <div class="width-20">
              <ul class="dropdown-menu style-category">
                <li class="products active" @click="selectCategory(0)">
                  <a class="dropdown-item style-item" href="#">All products</a>
                </li>
                <li class="main" @click="selectCategory(1)">
                  <a
                    class="dropdown-item style-item"
                    href="#"
                    aria-current="true"
                    >Main</a
                  >
                </li>
                <li
                  class="modifier"
                  @click="selectCategory(2)"
                  v-if="!isModifiderOptionGroup"
                >
                  <a class="dropdown-item style-item" href="#">Modifier</a>
                </li>
              </ul>
            </div>
            <div class="width-80 item-list">
              <div class="search">
                <div class="row align-items-center mt-0">
                  <div class="col-xl-4 col-12">
                    <label>Products for</label
                    ><label class="fw-bold">{{ label_type[type] }}</label>
                  </div>
                  <div class="col-xl-4 col-12">
                    <label
                      >Showing {{ items.length ?? 0 }} of
                      {{ items.length ?? 0 }} items</label
                    >
                  </div>
                  <div class="col-xl-4 col-12">
                    <input
                      class="nosubmit"
                      type="search"
                      id="keyword_search"
                      v-model="searchKeyword"
                      extraClass
                      name="keyword_search"
                      placeholder="search"
                      @input="onChangesearchKeyword"
                    />
                  </div>
                </div>
                <div class="row mt-0">
                  <div class="col-lg-4 col-12">
                    <Multiselect
                      v-model="type_id"
                      :options="types"
                      placeholder="Type"
                      @change="selectType"
                    />
                  </div>
                  <div class="col-lg-4 col-12">
                    <Multiselect
                      v-model="group_id"
                      :options="groups"
                      class="ms-2"
                      placeholder="Group"
                      :disabled="!type_id"
                      @change="searchItem"
                    />
                  </div>
                  <div class="col-lg-4 col-12">
                    <Multiselect
                      :searchable="true"
                      v-model="tag"
                      :options="tags"
                      placeholder="Tag"
                      @change="searchItem"
                    />
                  </div>
                </div>
                <div class="row mt-0">
                  <div class="col-lg-4 col-12">
                    <Multiselect 
                      :searchable="true"
                      v-model="category_id" 
                      :options="categoryDropdown"
                      placeholder="Category"
                      @change="changeCategory" 
                    />
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-3">
                  <material-button
                    class="action-btn"
                    @click="addSelectAllItem()"
                  >
                    Select all
                  </material-button>
                  <material-button
                    class="action-btn"
                    @click="removeItemActive()"
                  >
                    Remove selection
                  </material-button>
                </div>
              </div>
              <div class="content-item">
                <div class="col-12 list-item">
                  <div
                    class="item-css d-flex"
                    v-for="(item, key) in items"
                    :key="key"
                    :class="'item-' + item.item_id"
                    @click="selectItem(item.item_id)"
                  >
                    <img class="item-img" :src="item.image" alt="" />
                    <div>
                      <label>{{ item?.item_name }}</label
                      ><br />
                      <label>{{ item?.price_format }} </label>
                      <div
                        style="
                          width: 100%;
                          height: 20px;
                          display: flex;
                          gap: 10px;
                          align-items: center;
                          margin-top: 5px;
                        "
                        v-if="isModifiderOptionGroup"
                      >
                        <div
                          v-for="(itemVariations, key) in item.variations"
                          :key="key"
                          style="border: 2px solid white; border-radius: 50%"
                        >
                          <div
                            @click="
                              selectVariation(
                                item.item_id,
                                itemVariations.id,
                                $event
                              )
                            "
                            :style="{
                              backgroundColor: isVariationSelected(
                                item.item_id,
                                itemVariations.id
                              )
                                ? 'green'
                                : 'gray',
                            }"
                            style="
                              width: 25px;
                              height: 100%;
                              text-align: center;
                              color: white;
                              border-radius: 50%;
                              height: 25px;
                            "
                          >
                            {{ itemVariations.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" style="justify-content: unset">
            <material-button
              class="mx-2 btn btn-sm btn-background"
              @click="addItem()"
            >
              Add {{ idActive.length == 0 ? "" : idActive.length }} products
            </material-button>
            <material-button
              class="mx-2 btn btn-sm btn-background"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Cancel
            </material-button>
          </div>
          <material-loading :active="loaderActive" />
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-9999 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import MaterialButton from "@/components/MaterialButton.vue";
import ItemService from "../services/product/item.service";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import ModifierOptionGroupService from "../services/product/modifierOptionGroup";
import Multiselect from "@vueform/multiselect";

export default {
  name: "select-item",
  components: {
    MaterialButton,
    MaterialLoading,
    MaterialSnackbar,
    Multiselect,
  },
  props: {
    setId: {
      type: Number,
      required: true,
    },
    categoryId: {
      type: Number,
      required: true,
    },
    groupId: {
      type: Number,
      default: 0,
    },
    subGroupId: {
      type: Number,
      default: 0,
    },
    keyReload: {
      type: String,
      required: true,
    },
    isModifiderOptionGroup: {
      type: Boolean,
      required: false,
    },
    modifier_option_group_id: {
      type: Number,
      required: false,
    },
    date_time_now: {
      type: Object,
      required: false,
    },
    attachedToItemModifierOptionGroup: {
      type: Object,
      required: false,
    },
    itemByGroupOrSubGroupData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      snackbar: null,
      idActive: [],
      items: [],
      allItemId: [],
      searchKeyword: "",
      searchTimerId: null,
      type: 0,
      groups: [],
      dataGroupByType: [],
      types: [],
      type_id: 0,
      group_id: 0,
      tags: [],
      tag: "",
      label_type: [
        'All product',
        'Main',
        'Modifier'
      ],
      categoryDropdown: [],
      category_id: 0,
      loaderActive: false,
    };
  },
  async mounted() {
    // await this.getReportGroup();
    // await this.getListTag();
    // await this.getListItem("init");
    // await this.getCategoryByMenuSetDropdown();
  },
  methods: {
    async getListItem(type = "") {
      this.showLoader();
      var dataForm = {
        keyword: this.searchKeyword,
        type_id: this.type_id,
        group_id: this.group_id,
        tag: this.tag,
        set_id: this.setId ? this.setId : this.$route.params.id,
        type: this.type,
        category_id: this.categoryId,
        item_group_id: this.groupId,
        item_sub_group_id: this.subGroupId,
        category_id_by_filter: this.category_id
      };

      await ItemService.getAllItem(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.items = response.data.data.items;
            this.allItemId = response.data.data.allItemId;
            if (type == 'init') {
              this.loadActiveItem();
            } else {
              setTimeout(() => {
                this.idActive.forEach((id) => {
                  var name = ".item-" + id.itemId;
                  if (!$(name).hasClass("active")) {
                    $(name).addClass("active");
                  }
                });
              });
            }
          this.hideLoader();
        }
        (error) => {
          this.message = error;
          this.hideLoader();
        }
      });
    },

    async getReportGroup() {
      await ItemService.getReportGroup().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.types = response.data.data.dataTypeDropdown;
            this.dataGroupByType = response.data.data.dataGroupDropdown;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
    },

    async getListTag() {
      await ItemService.getListTag().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.tags = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
    },

    selectType(value) {
      this.group_id = 0;
      this.groups = this.dataGroupByType[value];
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getListItem();
      }, 100);
    },

    removeDuplicates(arr) {
      const uniqueItems = arr.filter(
        (item) =>
          typeof item !== "number" ||
          !arr.some((other) => other === item && typeof other === "number")
      );
      return uniqueItems;
    },

    addItem() {
      this.showLoader();
      var dataForm = {
        categoryId: this.categoryId,
        group_id: this.groupId,
        sub_group_id: this.subGroupId,
      };
      if (this.isModifiderOptionGroup) {
        // console.log("this.idActive", this.idActive)
        dataForm.itemsId = this.removeDuplicates(this.idActive);
        dataForm.modifier_option_group_id = this.modifier_option_group_id;
        if (
          this.modifier_option_group_id > 0
          // && dataForm.itemsId
          // && dataForm.itemsId.length > 0
        ) {
          // call add now
          ModifierOptionGroupService.attachedToItem(dataForm).then(
            (response) => {
              if (response.data.result.isSuccess) {
                // alert("success");
                $(".btn-close-modal").click();
                const today = new Date();
                const date =
                  today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate();
                const time =
                  today.getHours() +
                  ":" +
                  today.getMinutes() +
                  ":" +
                  today.getSeconds();
                const dateTime = date + " " + time;
                this.$emit("reload-list-attached-to-item", dateTime);
                this.removeItemActive();
              }
              this.hideLoader();
            },
            (error) => {
              this.message = error;
              this.hideLoader();
            }
          );
        } else {
          // add preview
          $(".btn-close-modal").click();
          this.hideLoader();
          this.$emit("add-preiew-item-selected", dataForm);
        }
      } else {
        dataForm.itemsId = this.idActive;
        ItemService.addItemToCategory(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              $(".btn-close-modal").click();
              this.$emit("reload-list-category", "Add item success");
            }
            this.hideLoader();
          },
          (error) => {
            this.message = error;
            this.hideLoader();
          }
        );
      }
    },

    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getListItem();
      }, 1000);
    },

    searchItem() {
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getListItem();
      }, 100);
    },

    addSelectAllItem() {
      if (this.isModifiderOptionGroup) {
        // console.log(this.allItemId);
        this.allItemId.forEach((id) => {
          this.selectItem(id);
        });
      } else {
        this.idActive = this.allItemId;
        this.idActive.forEach((id) => {
          var name = ".item-" + id;
          if (!$(name).hasClass("active")) {
            $(name).addClass("active");
          }
        });
      }
    },

    removeItemActive() {
      this.idActive = [];
      $(".item-css").removeClass("active");
    },

    selectItem(id) {
      var name = ".item-" + id;
      if ($(name).hasClass("active")) {
        if (this.isModifiderOptionGroup) {
          var indexToDelete = this.idActive.findIndex(function (item) {
            return item.itemId === id;
          });
          if (indexToDelete > -1) {
            this.idActive.splice(indexToDelete, 1);
          }
          $(name).removeClass("active");
        } else {
          this.idActive.splice(this.idActive.indexOf(id), 1);
          $(name).removeClass("active");
        }
      } else {
        if (this.isModifiderOptionGroup) {
          var exists = this.idActive.some(function (item) {
            return item.itemId === id;
          });
          if (!exists) {
            this.idActive.push({
              itemId: id,
              variationIds: [],
            });
          }
          $(name).addClass("active");
        } else {
          this.idActive.push(id);
          $(name).addClass("active");
        }
      }
    },

    selectCategory(val) {
      if (val == 1) {
        $(".main").addClass("active");
        $(".products").removeClass("active");
        $(".modifier").removeClass("active");
      } else if (val == 2) {
        $(".modifier").addClass("active");
        $(".products").removeClass("active");
        $(".main").removeClass("active");
      } else {
        $(".products").addClass("active");
        $(".main").removeClass("active");
        $(".modifier").removeClass("active");
      }
      this.type = val;
      this.getListItem();
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    isVariationSelected(itemId, variationId) {
      const item = this.idActive.find((item) => item.itemId === itemId);
      return item && item.variationIds.includes(variationId);
    },

    selectVariation(itemId, variationId, event) {
      // console.log(itemId);
      // console.log(variationId);
      // console.log(event);
      if (event) {
        event.stopPropagation();
      }
      // if(this.idActive.includes(itemId) == false) {
      // this.selectItem(itemId);
      // }
      const index = this.idActive.findIndex((item) => item.itemId === itemId);

      if (index !== -1) {
        if (!this.idActive[index].variationIds.includes(variationId)) {
          this.idActive[index].variationIds.push(variationId);
        } else {
          const variationIndex = this.idActive[index].variationIds.findIndex(
            (id) => id === variationId
          );
          this.idActive[index].variationIds.splice(variationIndex, 1);
          if (
            this.idActive[index].variationIds &&
            this.idActive[index].variationIds.length == 0
          ) {
            this.selectItem(itemId);
          }
        }
      } else {
        this.idActive.push({
          itemId,
          variationIds: [variationId],
        });
        this.selectItem(itemId);
      }
    },

    reloadItemActive() {
      if (this.isModifiderOptionGroup == true) {
        this.removeItemActive();
        if (
          this.attachedToItemModifierOptionGroup &&
          this.attachedToItemModifierOptionGroup.length > 0
        ) {
          const newArray = [];
          for (const item of this.attachedToItemModifierOptionGroup) {
            const existingItem = newArray.find(
              (newItem) => newItem.itemId === item.item_id
            );

            if (existingItem) {
              existingItem.variationIds.push(item.variation_group_options);
            } else {
              newArray.push({
                itemId: item.item_id,
                variationIds: [item.variation_group_options],
              });
            }
          }
          setTimeout(() => {
            console.log("newArray", newArray);
            newArray.forEach((item) => {
              this.selectItem(item.itemId);
              item.variationIds.forEach((itemVariationIds) => {
                this.selectVariation(item.itemId, itemVariationIds);
              });
            });
          }, 2000);
          
          // this.idActive = newArray;
          // this.idActive.forEach((item) => {
          //   var name = ".item-" + item.itemId;
          //   if (!$(name).hasClass("active")) {
          //     $(name).addClass("active");
          //   }
          // });
        }
      }
    },

    loadActiveItem() {
      this.removeItemActive();
      // this.itemByGroupOrSubGroupData.forEach((item) => {
      //     var name = ".item-" + item.id;
      //     this.idActive.push(item.id);
      //     $(name).addClass("active");
      // });
      // this.idActive = this.itemByGroupOrSubGroupData;

      setTimeout(() => {
        this.itemByGroupOrSubGroupData.forEach((item) => {
          var name = ".item-" + item.id;
          this.idActive.push(item.id);
          if (!$(name).hasClass("active")) {
            $(name).addClass("active");
          }
        });
      }, 100);
    },

    clearData() {
      this.idActive = [];
      this.items = [];
      this.itemSelect = [];
      this.allItemId = [];
      this.searchKeyword = "";
      this.type = 0;
      this.groups = [];
      this.types = [];
      this.dataGroupByType = [];
      this.group_id = 0;
      this.type_id = 0;
      this.tags = [];
      this.tag = "";
      $(".item-css").removeClass("active");
      $(".products").addClass("active");
      $(".main").removeClass("active");
      $(".modifier").removeClass("active");
    },

    async getCategoryByMenuSetDropdown() {
      var menu_set_id = this.setId ? this.setId : this.$route.params.id;
      if(menu_set_id) {
        var dataForm = {
            menu_set_id,
            merchantId: this.$route.params.merchant_id,
        };
        await ItemService.getCategoryByMenuSetDropdown(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.categoryDropdown = response.data.data;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
          }
        );
      }
    },

    changeCategory(category_id) {
      this.category_id = category_id;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getListItem("init");
      }, 100);
    },
  },
  watch: {
    async keyReload() {
      this.clearData();
      this.showLoader();
      await Promise.all([
        this.getReportGroup(),
        this.getListTag(),
        this.getListItem("init"),
        this.getCategoryByMenuSetDropdown()
      ])
      this.hideLoader();
    },
    date_time_now() {
      this.reloadItemActive();
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}
.modal-body .row {
  margin-top: 10px;
}
.modal-width {
  min-width: 60%;
}
.width-20 {
  width: 20%;
  border: 1px solid #dee2e6;
}
.width-80 {
  width: 80%;
  border: 1px solid #dee2e6;
  border-left: none;
  overflow-y: auto;
}
.style-category {
  display: unset !important;
  position: relative !important;
}
.search {
  padding: 15px 5px;
  border-bottom: 1px solid #dee2e6;
}
.search > .row > div {
  margin-top: 15px;
}
input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}
input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}
input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 2px solid #72959d;
}
input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}
.left-25 {
  margin-left: 25px;
}
.action-btn {
  float: right;
  margin-right: 17px;
  margin-top: 7px;
}
.content-item {
  width: 100%;
  height: 680px;
}
.item-css {
  cursor: pointer;
  border: 1px solid #d2d2d2;
  margin-top: 12px;
  border-radius: 6px;
  padding: 10px;
  width: calc(50% - 10px);
}
.item-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.selected {
  background-color: #20c997;
}
.list-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}
.style-item {
  padding: 10px 20px;
  border-bottom: 1px solid #dee2e6;
  font-size: 16px;
}
.style-category li.active a {
  color: #fff;
  background: #44bd32;
}
.active {
  background: #44bd32;
}
.active label {
  color: #fff !important;
}
.item-list {
  overflow-x: hidden;
}
.item-list::-webkit-scrollbar {
  width: 7px;
}
.item-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.item-list::-webkit-scrollbar-thumb {
  background: #888;
}
.item-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
