<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#addExistingItem"
      id="show-modal-add-existing-item"
    ></material-button>
    <div id="custom-overlay" class="custom-modal-overlay"></div>
    <div
      class="modal fade"
      id="addExistingItem"
      tabindex="-1"
      aria-hidden="true"
      style="z-index: 1056"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Add Existing Item
            </h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body" style="overflow-y: unset">
            <div class="group-option-list">
              <label class="fw-bold" for
                >Item No <span class="text-danger ms-2">*</span></label
              >
              <Multiselect
                :createOption="true"
                :searchable="true"
                :mode="'tags'"
                v-model="itemNo"
                :canClear="false"
                placeholder="Enter some Item No"
                :addOptionOn="['enter', ',']"
                :onCreate="onCreate"
              />
              <div class="mt-1">
                You can input multiple Item No, separated by commas (,).
              </div>
              <div class="mt-3 text-danger">
                <div v-for="(message, index) in messageErr" :key="index">
                  {{ message }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-5">
              <material-button
                class="mx-2 btn btn-sm btn-background"
                @click="addExistingItem()"
              >
                Add
              </material-button>
              <material-button
                class="mx-2 btn btn-sm btn-background"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </material-button>
            </div>
          </div>
        </div>
      </div>
      <material-loading :active="loaderActive" />
    </div>
    <div class="position-fixed top-1 end-1 z-index-9999 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>
<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import "@vueform/multiselect/themes/default.css";
import ItemService from "../services/product/item.service";
import $ from "jquery";

export default {
  name: "add-option-group",
  components: {
    MaterialLoading,
    MaterialSnackbar,
    MaterialButton,
    Multiselect,
  },
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
    groupId: {
      type: Number,
      default: 0,
    },
    subGroupId: {
      type: Number,
      default: 0,
    },
    keyReload: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      snackbar: null,
      message: "",
      loaderActive: false,
      itemNo: [],
      messageErr: [],
    };
  },
  methods: {
    onCreate(option) {
      if (option) {
        const tags = option.value
          .split(",")
          .map((tag) => tag.trim())
          .filter((tag) => tag);
        var newTags = [];

        tags.forEach((tag) => {
          if (!this.itemNo.includes(tag)) {
            newTags.push({
              value: tag,
              label: tag,
            });
          }
        });

        return newTags;
      }

      return false;
    },
    addExistingItem() {
      if (this.itemNo.length > 0) {
        this.showLoader()
        var dataForm = {
          categoryId: this.categoryId,
          group_id: this.groupId,
          sub_group_id: this.subGroupId,
          item_no: this.itemNo,
        };

        ItemService.addExistingItem(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.messageErr = response.data.data;

              if (this.messageErr.length == 0) {
                $(".btn-close-modal").click();
                this.$emit("reload-list-category", "Add item success");
              }
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
            this.hideLoader();
          }
        );
      }
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    keyReload() {
      this.itemNo = [];
      this.messageErr = [];
    },
  },
};
</script>
