<template>
    <div>
      <div class="row mb-2 pe-4">
        <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
          <div class="color-common">
            <h3 class="title-page mb-0 ps-3">Stock and Inventory Control</h3>
            <h3 class="title-page mb-0 ps-3">BRANCH - {{ items && items.length > 0 ? items[0].branch_name : "" }}</h3>
          </div>
        </div>
        <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end" v-if="isMobile == false">
          <material-button
            @click="token ? handleEnableOrDisableAll(false) : check_passcode_is_empty == 0 || isAuthorized == true ? handleEnableOrDisableAll(false) : checkPasscodeStock(1)"
            class="mx-2 btn-background btn-sm"
          >
            Disable
          </material-button>
          <material-button
            @click="token ? handleEnableOrDisableAll(true) : check_passcode_is_empty == 0 || isAuthorized == true ? handleEnableOrDisableAll(true) : checkPasscodeStock(2)"
            class="mx-2 btn-background btn-sm"
          >
            Enable
          </material-button>
        </div>
      </div>
      <div class="page-header align-items-start min-vh-100 my-transacions">
        <span class="mask bg-white"></span>
        <div class="container">
          <div class="row">
            <div class="notifications">
              <material-alert
                v-if="error"
                color="danger"
                icon="ni ni-like-2 ni-lg"
                dismissible
              >
                {{ error }}
              </material-alert>
            </div>
            <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isLoadData">
              <div class="row my-3 d-flex align-items-left">
                <div class="col-lg-12 col-md-12 col-12" style="display: flex;justify-content: space-between;align-items: center;" v-if="isMobile == true">
                  <div style="width: 100%">
                    <input
                      class="nosubmit"
                      type="search"
                      id="keyword_search"
                      v-model="searchKeyword"
                      extraClass
                      name="keyword_search"
                      placeholder="Search item name or item code..."
                      @input="onChangesearchKeyword"
                    />
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12" style="display: flex;justify-content: space-between;align-items: center;" v-else>
                  <div style="width: 30%">
                    <Multiselect v-model="category_id" :options="categoryDropdown" :canClear="false" @change="changeCategory" />
                  </div>
                  <div style="width: 30%">
                    <input
                      class="nosubmit"
                      type="search"
                      id="keyword_search"
                      v-model="searchKeyword"
                      extraClass
                      name="keyword_search"
                      placeholder="Search item name or item code..."
                      @input="onChangesearchKeyword"
                    />
                  </div>
                </div>
              </div>
              <div class="row d-flex mb-3">
                <material-checkbox
                    name="in-app"
                    class="align-items-center px-3"
                    label="All"
                    style="width: 200px;"
                    :checked="searchByTypeArray && searchByTypeArray.length == 0 ? true : false"
                    value="0"
                    @change="changeSearchByType(0)"
                ></material-checkbox>
                <material-checkbox
                    name="in-app"
                    class="align-items-center px-3"
                    label="Main"
                    style="width: 200px;"
                    :checked="searchByTypeArray.indexOf(1) !== -1 ? true : false"
                    value="1"
                    @change="changeSearchByType(1)"
                ></material-checkbox>
                <material-checkbox
                    name="in-app"
                    class="align-items-center px-3"
                    label="Modifier"
                    style="width: 200px;"
                    value="2"
                    :checked="searchByTypeArray.indexOf(2) !== -1 ? true : false"
                    @change="changeSearchByType(2)"
                ></material-checkbox>
                <material-checkbox
                    name="in-app"
                    class="align-items-center px-3"
                    label="Combo"
                    style="width: 200px;"
                    value="3"
                    :checked="searchByTypeArray.indexOf(3) !== -1 ? true : false"
                    @change="changeSearchByType(3)"
                ></material-checkbox>
              </div>
              <div class="row my-3 d-flex align-items-left" v-if="isMobile == true">
                <div class="col-lg-12 col-md-12 col-12" style="display: flex;justify-content: space-between;align-items: center;">
                  <div style="width: 100%">
                    <Multiselect v-model="category_id" :options="categoryDropdown" :canClear="false" @change="changeCategory" />
                  </div>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-12">
                  <div>
                    <div class="px-0 pb-2">
                      <div class="table-responsive p-0 custom-table">
                        <table style="width: 100%;" v-if="isMobile == true">
                          <thead>
                              <tr>
                                <th style="width: 50%;" class="text-center font-weight-bolder">
                                  {{ $t('Item') }}
                                </th>
                                <th style="width: 5%;" class="text-center font-weight-bolder">
                                  {{ $t('On Off') }}
                                </th>
                                <th style="width: 40%;" class="text-center font-weight-bolder">
                                  {{ $t('Count Down') }}
                                </th>
                              </tr>
                          </thead>
                          <tbody v-if="items.length">
                                <tr v-for="(value, key) in items" :key="key">
                                    <td style="width: 55%">
                                      <span v-html="formatItemNo(value)"></span>
                                    </td>
                                    <td
                                      style="width: 5%"
                                    >
                                      <div
                                        :class="{ active: value.is_not_available == 0 ? true : false }"
                                        class="toggle_container mt-4"
                                      >
                                        <MaterialToggleButton
                                          labelEnableText=""
                                          labelDisableText=""
                                          :id="value.id + 'event_handle_available'"
                                          v-on:change="token ? triggerEventAvailable($event, value) : check_passcode_is_empty == 0 || isAuthorized == true ? triggerEventAvailable($event, value) : checkPasscodeTriggerItem($event, value)"
                                        />
                                      </div>
                                    </td>
                                    <td style="width: 40%">
                                      <div style="display: flex;align-items: center;">
                                        <div>
                                          <material-input
                                            v-model="value.stock_balance"
                                            type="text"
                                            class="text-stock-balance"
                                          />
                                          <i @click="token ? onChangeStock(value) : check_passcode_is_empty == 0 || isAuthorized == true ? onChangeStock(value) : checkPasscodeChangeStockBalance(value)" style="cursor: pointer;margin-left: 3px;" class="material-icons-round opacity-10 fs-5">save</i>
                                        </div>
                                        <div
                                          :class="{ active: value.stock_enable_balance == 1 ? true : false }"
                                          class="toggle_container mt-3"
                                        >
                                          <MaterialToggleButton
                                            labelEnableText=""
                                            labelDisableText=""
                                            :id="value.id + 'event_handle_stock_on_off'"
                                            v-on:change="token ? triggerEventStockOnOff($event, value) : check_passcode_is_empty == 0 || isAuthorized == true ? triggerEventStockOnOff($event, value) : checkPasscodeTriggerStockOnOff($event, value)"
                                            style="margin-top: 12px;"
                                            :disabled="value.is_not_available != 0 ? true : false"
                                          />
                                        </div>
                                      </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                <td colspan="10" class="text-center">No item available</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table align-items-center mb-0" v-else>
                          <thead>
                            <tr>
                                <th class="text-center font-weight-bolder w-5">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :checked="isAllSelected" @change="selectAll">
                                    </div>
                                </th>
                                <th class="text-center font-weight-bolder w-10">
                                    {{ $t('Category') }}
                                </th>
                                <th class="text-center font-weight-bolder w-10">
                                    {{ $t('Item') }}
                                </th>
                                <!-- <th class="text-center font-weight-bolder w-5">
                                    {{ $t('Type') }}
                                </th> -->
                                <!-- <th class="text-center font-weight-bolder w-10">
                                    {{ $t('Item Description') }}
                                </th> -->
                                <th class="text-center font-weight-bolder w-10">
                                    {{ $t('On Off') }}
                                </th>
                                <th class="text-center font-weight-bolder w-5">
                                    {{ $t('Count Down') }}
                                </th>
                            </tr>
                          </thead>
                            <tbody v-if="items.length">
                                <tr v-for="(value, key) in items" :key="key">
                                    <td class="text-center text-xss">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" :value="value.id" v-model="selectedItems">
                                        </div>
                                    </td>
                                    <td class="text-center text-xss">
                                        {{value.category_name}}
                                    </td>
                                    <td class="text-center text-xss">
                                        {{value.item_no}}. {{value.item_name}}
                                    </td>
                                    <!-- <td class="text-center text-xss">
                                        {{value.item_type_format}}
                                    </td> -->
                                    <!-- <td class="text-center text-xss">
                                        {{value.item_name}}
                                        <br />
                                        {{value.description}}
                                    </td> -->
                                    <!-- <td class="text-center text-xss">
                                        {{value.description}}
                                    </td> -->
                                    <td
                                      class="text-center text-xss"
                                    >
                                      <div
                                        :class="{ active: value.is_not_available == 0 ? true : false, 'mt-4': value.is_not_available === 0, 'mt-3': value.is_not_available !== 0 }"
                                        class="toggle_container"
                                      >
                                        <MaterialToggleButton
                                          labelEnableText=""
                                          labelDisableText=""
                                          :id="value.id + 'event_handle_available'"
                                          v-on:change="token ? triggerEventAvailable($event, value) : check_passcode_is_empty == 0 || isAuthorized == true ? triggerEventAvailable($event, value) : checkPasscodeTriggerItem($event, value)"
                                        />
                                      </div>
                                    </td>
                                    <td class="text-center text-xss">
                                      <div style="display: flex;align-items: center;justify-content: center;" v-if="!value.stock_enable_balance">
                                        <div>
                                          {{ value.stock_balance }}
                                        </div>
                                        <MaterialToggleButton
                                            labelEnableText=""
                                            labelDisableText=""
                                            :id="value.id + 'event_handle_stock_on_off'"
                                            v-on:change="token ? triggerEventStockOnOff($event, value) : check_passcode_is_empty == 0 || isAuthorized == true ? triggerEventStockOnOff($event, value) : checkPasscodeTriggerStockOnOff($event, value)"
                                            style="margin-top: 15px;"
                                            :disabled="value.is_not_available != 0 ? true : false"
                                          />
                                      </div>
                                      <div style="display: flex;align-items: center;" v-else>
                                        <material-input
                                          v-model="value.stock_balance"
                                          type="text"
                                          class="text-stock-balance"
                                        />
                                        <i @click="token ? onChangeStock(value) : check_passcode_is_empty == 0 || isAuthorized == true ? onChangeStock(value) : checkPasscodeChangeStockBalance(value)" style="cursor: pointer;" class="material-icons-round opacity-10 fs-5">save</i>
                                        <div
                                          :class="{ active: value.stock_enable_balance == 1 ? true : false }"
                                          class="toggle_container mt-3"
                                        >
                                          <MaterialToggleButton
                                            labelEnableText=""
                                            labelDisableText=""
                                            :id="value.id + 'event_handle_stock_on_off'"
                                            v-on:change="token ? triggerEventStockOnOff($event, value) : check_passcode_is_empty == 0 || isAuthorized == true ? triggerEventStockOnOff($event, value) : checkPasscodeTriggerStockOnOff($event, value)"
                                            style="margin-top: 12px;"
                                          />
                                        </div>
                                      </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                <td colspan="10" class="text-center">No item available</td>
                                </tr>
                            </tbody>
                        </table>
                      </div>
                    </div>
                    <pagination
                      v-if="pagination.last_page > 1"
                      :pagination="pagination"
                      :current_page="pagination.current_page"
                      :last_page="pagination.last_page"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
          <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title=""
            date=""
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
          />
        </div>

        <div>
          <button
            style="display: none"
            type="button"
            id="action-group-stock"
            data-bs-toggle="modal"
            data-bs-target="#modalCheckStockPasscodePage"
          ></button>
          <div class="modal fade" id="modalCheckStockPasscodePage" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">PASSCODE REQUIRED</h5>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <label class="control-label" style="margin-left: 0px"
                      ><strong> Please enter passcode to continue.</strong></label
                    >
                    <div>
                      <material-input
                        v-model="passcodeStockValue"
                        extraClass="ml-5"
                        placeholder="Enter passcode"
                      />
                      <span class="is-invalid" v-if="passcodeIncorrect"
                        >{{ passcodeIncorrect }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- Modal content-->
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-success btn-background btn-sm"
                    @click="checkPasscode"
                  >
                    Ok
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-close-modal btn-sm"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <material-loading :active="loaderActive" />
  </template>
  
  <script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import Pagination from "@/components/Pagination.vue";
  import "bootstrap/js/dist/modal";
  import MaterialButton from "@/components/MaterialButton.vue";
  import "@vuepic/vue-datepicker/dist/main.css";
//   import ProductService from "../../services/product.service";
  import TokenService from "../../services/token.service";
  import MaterialToggleButton from "@/components/MaterialToggleButton.vue";
  import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
  import ItemService from "../../services/product/item.service";
  import "@vueform/multiselect/themes/default.css";
  import Multiselect from "@vueform/multiselect";
  import MaterialInput from "@/components/MaterialInput.vue";
  import $ from "jquery";

  export default {
    name: "find-store",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      MaterialButton,
      Pagination,
      MaterialToggleButton,
      MaterialCheckbox,
      Multiselect,
      MaterialInput
    },
    data() {
      return {
        message: "",
        snackbar: null,
        loaderActive: false,
        items: [],
        searchKeyword: "",
        pagination: {},
        page: 1,
        isLoadData: false,
        merchantCode: "",
        token: TokenService.getLocalAccessToken(),
        nodeEnv: process.env.VUE_APP_NODE_ENV,
        searchTimerId: null,
        searchByTypeDefault: 0,
        searchByTypeArray: [],
        is_combo: 1,
        selectedItems: [],
        categoryDropdown: [],
        category_id: 0,
        enterStockTimerId: null,
        passcodeStockValue: '',
        type: '',
        passcodeIncorrect: '',
        eventChangeStockBalance: null,
        itemChangeStockBalance: null,
        isMobile: false,
        check_passcode_is_empty: 0,
        isAuthorized: false,
        timeoutHandle: null
      };
    },
    created() {
        if (this.$route.query.page) {
            this.page = this.$route.query.page;
        }
        this.getList(this.page);
    },
    mounted() {
        this.checkWindowSize();
        window.addEventListener('resize', this.checkWindowSize);
        const code = localStorage.getItem("MERCHANT_CODE", "");
        this.merchantCode = code ? `/${code}` : "";
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.checkWindowSize);
    },
    methods: {
        checkWindowSize() {
            if (window.innerWidth < 1200) {
              this.isMobile = true;
            } else {
              this.isMobile = false;
            }
        },
        closeSnackbar() {
            this.snackbar = null;
        },
    
        setTimeOutNotification() {
            setTimeout(() => {
            this.snackbar = null;
            }, 3000);
        },
    
        onChangesearchKeyword(event) {
            this.searchKeyword = event.target.value;
            clearTimeout(this.searchTimerId);
            this.searchTimerId = setTimeout(() => {
                this.getList(this.page);
            }, 1000);
        },
  
        getList(page) {
            // this.isLoadData = false;
            // this.items = [];
            this.showLoader();
            var dataForm = {
                search_keyword: this.searchKeyword,
                page: page,
                merchantId: this.$route.params.merchant_id,
                branchCode: this.$route.params.branch_code,
                searchByTypeArray: this.searchByTypeArray.filter(item => item !== 0 && item !== 3),
                is_combo: this.is_combo,
                category_id: this.category_id
            };
            ItemService.getAllStockItems(dataForm).then(
            (response) => {
                if (response.data.result.isSuccess) {
                    this.check_passcode_is_empty = response.data.data.check_passcode_is_empty;
                    this.items = response.data.data.items;
                    if(response.data.data.items && response.data.data.items.length > 0) {
                        this.getCategoryByMenuSetDropdown(response.data.data.items[0].set_id);
                    }
                    this.pagination = response.data.data;
                    this.page = this.pagination.current_page;
                    this.$router.push({
                        query: Object.assign({}, this.$route.query, { page: this.page }),
                    });
                    this.isLoadData = true;
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                this.hideLoader();
            },
            (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                this.setTimeOutNotification();
            }
            );
        },
        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
        changeSearchByType(type) {
            if(type == 1 || type == 2) {
                const index = this.searchByTypeArray.indexOf(type);
                if (index === -1) {
                    this.searchByTypeArray.push(type);
                } else if (index !== -1) {
                    this.searchByTypeArray.splice(index, 1);
                }
            } else if (type == 0) {
                this.searchByTypeArray = [];
                this.searchByTypeDefault = 0;
                this.is_combo = 1;
            } else {
                const index1 = this.searchByTypeArray.indexOf(3);
                if (index1 === -1) {
                    this.is_combo = 2;
                    this.searchByTypeArray.push(type);
                } else if (index1 !== -1) {
                    this.is_combo = 1;
                    this.searchByTypeArray.splice(index1, 1);
                }
            }
            this.getList();
        },
        selectAll(event) {
          if (event.target.checked) {
          this.selectedItems = this.items.map(item => item.id)
          } else {
          this.selectedItems = []
          }
        },
        handleEnableOrDisableAll(is_disabled) {
          this.resetTimeout();
            if(this.selectedItems && this.selectedItems.length > 0) {
            // this.isLoadData = false;
            this.showLoader();
            var dataForm = {
                is_disabled: is_disabled,
                selectedItems: this.selectedItems,
                branchCode: this.$route.params.branch_code,
                merchantId: this.$route.params.merchant_id,
            };
            ItemService.handleEnableOrDisableAvailableAll(dataForm).then(
                (response) => {
                if (response.data.result.isSuccess) {
                    this.snackbar = "success";
                    this.message = "Updated successfully!";
                    this.setTimeOutNotification();
                    this.getList(this.page);
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                },
                (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.setTimeOutNotification();
                }
            );
            this.hideLoader();
            } else {
            this.snackbar = "danger";
            this.message = "You must select least 1 column to export file."
            this.setTimeOutNotification();
            }
        },
        handleEnableOrDisable(item) {
            // this.isLoadData = false;
            this.showLoader();
            var dataForm = {
              id: item.id,
              value: item.is_not_available ? 0 : 1,
              branchCode: this.$route.params.branch_code,
              merchantId: this.$route.params.merchant_id,
            };
            ItemService.handleEnableOrDisableAvailable(dataForm).then(
            (response) => {
                if (response.data.result.isSuccess) {
                    this.snackbar = "success";
                    this.message = "Updated successfully!";
                    this.setTimeOutNotification();
                    this.getList(this.page);
                    this.getMenuSetDropdown();
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                // this.eventChangeStockBalance = null;
                this.itemChangeStockBalance = null;
            },
            (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.setTimeOutNotification();
            }
            );
            this.hideLoader();
        },
        getCategoryByMenuSetDropdown() {
            // this.isLoadData = false;
            this.showLoader();
            var dataForm = {
                branchCode: this.$route.params.branch_code,
                merchantId: this.$route.params.merchant_id,
            };
            ItemService.getCategoryByMenuSetDropdown(dataForm).then(
            (response) => {
                if (response.data.result.isSuccess) {
                this.categoryDropdown = response.data.data;
                this.isLoadData = true;
                } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
                }
            },
            (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.setTimeOutNotification();
            }
            );
            this.hideLoader();
        },
        changeCategory(category_id) {
            this.category_id = category_id;
            this.page = 1;
            this.getList(this.page);
        },
        triggerEventAvailable(event, value) {
          this.resetTimeout();
          if (typeof event == "boolean") {
            this.handleEnableOrDisable(value);
          }
        },
        triggerEventStockOnOff(event, value) {
          this.resetTimeout();
          if (typeof event == "boolean") {
            this.handleStockOnOff(value);
          }
        },
        onChangeStock(item) {
          this.resetTimeout();
          clearTimeout(this.enterStockTimerId);
          this.enterStockTimerId = setTimeout(() => {
            // this.isLoadData = false;
            this.showLoader();
            var dataForm = {
              id: item.id,
              stock_balance: item.stock_balance,
              branchCode: this.$route.params.branch_code,
              merchantId: this.$route.params.merchant_id,
            };
            ItemService.updateStockBalanceByItem(dataForm).then(
            (response) => {
                if (response.data.result.isSuccess) {
                    this.snackbar = "success";
                    this.message = "Updated successfully!";
                    this.setTimeOutNotification();
                    this.getList(this.page);
                    this.getMenuSetDropdown();
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                this.eventChangeStockBalance = null;
                this.itemChangeStockBalance = null;
            },
            (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.setTimeOutNotification();
            }
            );
            this.isLoadData = true;
            this.hideLoader();
          }, 1000);
        },
        checkPasscodeStock(type) {
          this.type = type;
          const editGroup = document.getElementById("action-group-stock");
          editGroup.click();
        },
        checkPasscodeChangeStockBalance(item) {
          this.itemChangeStockBalance = item;
          this.type = 3;
          const editGroup = document.getElementById("action-group-stock");
          editGroup.click();
        },
        checkPasscodeTriggerItem(event, item) {
          this.itemChangeStockBalance = item;
          this.type = 4;
          const editGroup = document.getElementById("action-group-stock");
          editGroup.click();
        },
        checkPasscodeTriggerStockOnOff(event, item) {
          this.itemChangeStockBalance = item;
          this.type = 5;
          const editGroup = document.getElementById("action-group-stock");
          editGroup.click();
        },
        checkPasscode() {
          this.passcodeIncorrect = "";
          // this.isLoadData = false;
          this.showLoader();
          var dataForm = {
            merchantId: this.$route.params.merchant_id,
            branchCode: this.$route.params.branch_code,
            passcode: this.passcodeStockValue
          };
          ItemService.checkPasscodeStockPage(dataForm).then(
          (response) => {
              if (response.data.result.isSuccess && response.data.data) {
                this.isAuthorized = true;
                this.resetTimeout();
                this.passcodeIncorrect = "";
                if(this.type == 1) {
                  this.type = "";
                  this.passcodeStockValue = "";
                  this.handleEnableOrDisableAll(false);
                } else if(this.type == 2) {
                  this.type = "";
                  this.passcodeStockValue = "";
                  this.handleEnableOrDisableAll(true);
                } else if(this.type == 3) {
                  this.type = "";
                  this.passcodeStockValue = "";
                  this.onChangeStock(this.itemChangeStockBalance);
                }  else if(this.type == 4) {
                  this.type = "";
                  this.passcodeStockValue = "";
                  this.handleEnableOrDisable(this.itemChangeStockBalance);
                } else if(this.type == 5) {
                  this.type = "";
                  this.passcodeStockValue = "";
                  this.handleStockOnOff(this.itemChangeStockBalance);
                }
                $(".btn-close-modal").click();
              } else {
                this.passcodeIncorrect = "Passcode incorrect!";
              }
          },
          (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.setTimeOutNotification();
          }
          );
          this.isLoadData = true;
          this.hideLoader();
        },
        handleStockOnOff(item) {
          // this.isLoadData = false;
          this.showLoader();
          var dataForm = {
            id: item.id,
            value: item.stock_enable_balance ? 0 : 1,
            branchCode: this.$route.params.branch_code,
            merchantId: this.$route.params.merchant_id,
          };
          ItemService.handleStockOnOff(dataForm).then(
          (response) => {
              if (response.data.result.isSuccess) {
                  this.snackbar = "success";
                  this.message = "Updated successfully!";
                  this.setTimeOutNotification();
                  this.getList(this.page);
                  this.getMenuSetDropdown();
              } else {
                  this.snackbar = "danger";
                  this.message = response.data.result.message;
                  this.setTimeOutNotification();
              }
              // this.eventChangeStockBalance = null;
              this.itemChangeStockBalance = null;
          },
          (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.setTimeOutNotification();
          }
          );
          this.hideLoader();
        },
        formatItemNo(item) {
          var itemNo = item.item_no;
          const maxLength = 5;
          if (itemNo.length > maxLength) {
              const regex = new RegExp(`.{1,${maxLength}}`, 'g');
              return itemNo.match(regex).join('<br>')+". "+item.item_name;
          } else {
              return itemNo+". "+item.item_name;
          }
        },
        resetTimeout() {
          if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
          }
          this.timeoutHandle = setTimeout(this.removePasscodeWhenExpired, 30 * 60 * 1000); // 30 minutes
        },
        removePasscodeWhenExpired() {
          this.isAuthorized = false;
        }
    },
    computed: {
      isAllSelected: {
        get() {
          return this.selectedItems && this.selectedItems.length === this.items.length
        },
        set(value) {
          if (value) {
              this.selectedItems = this.items.map(item => item.id)
          } else {
              this.selectedItems = []
          }
        }
      }
    }
  };
  </script>
  <style scoped>
  .text-title {
    color: #133067  !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }
  
  .text-label {
    color: #5b848d !important;
    font-weight: 500 !important;
    font-size: 17px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }
  
  .checkbox-auto-save label {
    padding-top: 11px !important;
  }
  
  input[type="search"] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
  }
  
  input[type="search"]::placeholder {
    color: #bbb;
    text-align: left;
    font-weight: normal;
  }
  
  input[type="search"]:focus {
    border-color: #72959d;
    outline: none;
    border: none;
    border-bottom: 1px solid #72959d;
  }
  
  .nosubmit-parent {
    border: none;
    padding: 0;
  }
  
  input.nosubmit {
    border-bottom: 1px solid #d2d2d2;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat 13px center;
  }
  
  .custom-name {
    word-break: break-all;
    max-width: 200px;
    white-space: normal;
  }

  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    text-align: center !important;
    margin-left: 0px;
  }
  </style>