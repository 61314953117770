<template>
  <div>
    <div class="row mb-3 pe-4">
      <div class="col-12">
        <div class="color-common">
          <div class="d-flex align-items-center w-100">
            <h3 class="title-page mt-1 ps-3" style="width: 120px">Menu Set</h3>
            <div class="d-flex s-menuset align-items-center w-100">
              <Multiselect
                v-model="menuSetIdSelect"
                :options="menusets"
                placeholder="Select menu"
                :canClear="false"
                :canDeselect="false"
                @change="selectMenuset"
              >
                <template v-slot:option="{ option }">
                  <!-- Use v-html to render HTML content -->
                  <div v-if="option.value != 999999">{{ option.label }}</div>
                  <div v-else v-html="option.html"></div>
                </template>
              </Multiselect>
              <a class="text-decoration-underline tag-a-custom ms-3" href="#"
                >Preview</a
              >
              <a
                v-if="menuSetIdSelect && isLoadData"
                class="text-decoration-underline tag-a-custom ms-3"
                @click="openModalAddMenuset('edit')"
                >Property</a
              >
              <a
                class="text-decoration-underline tag-a-custom ms-3"
                @click="this.$router.push('/menu-set/import')"
                >Import</a
              >
              <a
                v-if="menuSetIdSelect && isLoadData"
                class="text-decoration-underline tag-a-custom ms-3"
                @click="exportMenu()"
                >Export</a
              >
              <a
                class="text-decoration-underline tag-a-custom ms-3"
                @click="openModalListMenuset()"
                >View All</a
              >
              <a
                class="text-decoration-underline tag-a-custom ms-3"
                @click="openModalAddMenuset()"
                >+ Add New</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="mt-3" v-if="isShowCategory">
              <div class="row d-flex justify-content-center">
                <ul id="general-tab" class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      id="nav_item_1"
                      class="nav-link"
                      :class="{ active: type == 1 }"
                      @click="changeTab(1)"
                      >MAIN</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      id="nav_item_2"
                      class="nav-link"
                      :class="{ active: type == 2 }"
                      @click="changeTab(2)"
                      >MODIFIERS</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      id="nav_item_3"
                      class="nav-link"
                      @click="changeTab(3)"
                      style="
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        gap: 10px;
                      "
                      >MODIFIER OPTION GROUP
                      <i
                        v-tooltip.bottom="
                          'An Option Group is attached to a main item for modifier selection. It can be made up of 1 or multiple mod categories. Each group with _default in the name is system generated and not editable, it is linked to original modifier group. Its modifiers can only be edited in MODIFIERS tab.'
                        "
                        style="cursor: pointer; width: 5px"
                        class="material-icons"
                        >information</i
                      >
                    </a>
                  </li>
                </ul>
              </div>
              <div v-if="type == 1 || type == 2">
                <div style="margin-top: 15px; margin-bottom: 50px">
                  <div class="d-flex" v-if="categories.length">
                    <div class="m-left-menu">
                      <draggable
                        v-model="categories"
                        group="categories"
                        @start="onStartDragCategory"
                        @end="onEndDragCategory"
                        item-key="id"
                        handle=".handleMoveCategory"
                      >
                        <template #item="{ index, element }">
                          <div>
                            <div
                              :class="{
                                hovered: categoryHoveredRow === index,
                              }"
                              @mouseenter="categoryHoveredRow = index"
                              @mouseleave="categoryHoveredRow = null"
                              :style="getStyleCategory(index, element.id)"
                            >
                              <div
                                style="width: 85%"
                                @click="itemByCategory(element)"
                              >
                                <div class="d-flex align-items-center">
                                  <i
                                    class="material-icons-round opacity-10 handleMoveCategory"
                                    >drag_indicator_icon</i
                                  >
                                  <img
                                    :src="element.img_version"
                                    class="me-2"
                                    alt="Image"
                                    width="35"
                                    height="35"
                                  />
                                  <div>{{ element.second_name }}</div>
                                </div>
                              </div>
                              <div
                                @click="itemByCategory(element)"
                                class="d-flex align-items-center justify-content-end"
                                style="width: 10%"
                              >
                                <i
                                  v-if="[1, 3].includes(element.schedule_type)"
                                  class="material-icons me-2"
                                  style="margin-top: 3px"
                                  >event_busy</i
                                >
                                <!-- <img
                                  v-if="element.is_feature"
                                  class="me-2"
                                  width="20"
                                  height="20"
                                  src="@/assets/img/star_checked.png"
                                  alt="logo"
                                />
                                <img
                                  v-else
                                  class="me-2"
                                  width="20"
                                  height="20"
                                  src="@/assets/img/star_uncheck.png"
                                  alt="logo"
                                /> -->
                              </div>
                              <div
                                class="dropdown"
                                style="width: 5%; text-align: end"
                              >
                                <i
                                  class="dropdownMenuButton"
                                  :class="
                                    element.id == categoryIdSelectToHilight
                                      ? 'fas fa-ellipsis-v text-white'
                                      : 'fas fa-ellipsis-v text-dark'
                                  "
                                  id="dropdownCategory1"
                                  data-bs-toggle="dropdown"
                                ></i>
                                <ul
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownCategory1"
                                >
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      @click="openModalGroup(element.id)"
                                    >
                                      <i class="material-icons me-1">add</i>
                                      <span>Add Sub Category</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      @click="selectCategory(element.id)"
                                      class="dropdown-item"
                                      href="#"
                                      ><i class="material-icons me-1">add</i>
                                      <span>Add Item</span></a
                                    >
                                  </li>
                                  <li>
                                    <a
                                      @click="
                                        openModalAddCategory(element.id, 'edit')
                                      "
                                      class="dropdown-item"
                                      href="#"
                                      ><i class="material-icons me-1">edit</i>
                                      <span>Edit Category</span></a
                                    >
                                  </li>
                                  <li v-if="element.type == 1">
                                    <a
                                      @click="addScheduleItem(0, element.id)"
                                      class="dropdown-item"
                                      href="#"
                                      ><i class="material-icons me-1"
                                        >event_busy</i
                                      >
                                      <span>Add Schedule</span></a
                                    >
                                  </li>
                                  <li>
                                    <a
                                      @click="deleteCategory(element.id)"
                                      class="dropdown-item"
                                      href="#"
                                      ><i class="material-icons me-1">delete</i>
                                      <span>Delete Category</span></a
                                    >
                                  </li>
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      @click="
                                        openModalAddPrinter(
                                          element.id,
                                          'category',
                                          element
                                        )
                                      "
                                      ><i class="material-icons me-1">add</i>
                                      <span>Add Printer</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              v-for="(item, index) in element.groups"
                              :key="index"
                            >
                              <div :style="getStyleGroup(item.id)">
                                <div
                                  style="width: 90%; padding-left: 15px"
                                  @click="itemByGroup(item)"
                                >
                                  <i
                                    :class="
                                      item.id == groupIdSelectToHilight
                                        ? 'fas fa-caret-right text-white'
                                        : 'fas fa-caret-right text-dark'
                                    "
                                    aria-hidden="true"
                                  ></i>
                                  {{ item.name }}
                                </div>
                                <div
                                  class="dropdown"
                                  style="width: 5%; text-align: end"
                                >
                                  <i
                                    class="dropdownMenuButton"
                                    :class="
                                      item.id == groupIdSelectToHilight
                                        ? 'fas fa-ellipsis-v text-white'
                                        : 'fas fa-ellipsis-v text-dark'
                                    "
                                    id="dropdownGroup1"
                                    data-bs-toggle="dropdown"
                                    data-keyboard="false"
                                    data-backdrop="static"
                                  ></i>
                                  <ul
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownGroup1"
                                  >
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        href="#"
                                        @click="
                                          openModalAddSubGroup(
                                            item.id,
                                            element.id
                                          )
                                        "
                                        >Add Sub Group</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        @click="
                                          handleAddItem(
                                            element.id,
                                            item.id,
                                            0,
                                            item,
                                            event
                                          )
                                        "
                                        class="dropdown-item"
                                        href="#"
                                        >Add Item</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        href="#"
                                        @click="
                                          openModalEditGroup(
                                            item.id,
                                            element.id
                                          )
                                        "
                                        >Edit Sub Category</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        href="#"
                                        @click="deleteGroup(item.id)"
                                        >Delete Sub Category</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div
                                v-for="(itemSubGroup, index) in item.sub_groups"
                                :key="index"
                                style="
                                  border: 1px solid #ccc;
                                  border-top: 0px;
                                  justify-content: space-between;
                                  align-items: center;
                                  display: flex;
                                "
                              >
                                <div
                                  :style="getStyleSubGroup(itemSubGroup.id)"
                                  style="
                                    justify-content: space-between;
                                    align-items: center;
                                    display: flex;
                                    width: 100%;
                                    padding-left: 35px;
                                  "
                                >
                                  <div
                                    style="width: 90%"
                                    @click="itemBySubGroup(itemSubGroup)"
                                  >
                                    <i
                                      :class="
                                        itemSubGroup.id ==
                                        subGroupIdSelectToHilight
                                          ? 'fas fa-caret-right text-white'
                                          : 'fas fa-caret-right text-dark'
                                      "
                                      aria-hidden="true"
                                    ></i>
                                    {{ itemSubGroup.name }}
                                  </div>
                                  <div
                                    class="dropdown"
                                    style="width: 5%; text-align: end"
                                  >
                                    <i
                                      class="dropdownMenuButton"
                                      :class="
                                        itemSubGroup.id ==
                                        subGroupIdSelectToHilight
                                          ? 'fas fa-ellipsis-v text-white'
                                          : 'fas fa-ellipsis-v text-dark'
                                      "
                                      aria-hidden="true"
                                      id="dropdownSubGroup1"
                                      data-bs-toggle="dropdown"
                                      data-keyboard="false"
                                      data-backdrop="static"
                                    ></i>
                                    <ul
                                      class="dropdown-menu"
                                      aria-labelledby="dropdownSubGroup1"
                                    >
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href="#"
                                          @click="
                                            openModalEditSubGroup(
                                              itemSubGroup.id,
                                              element.id
                                            )
                                          "
                                          >Edit Sub Group</a
                                        >
                                      </li>
                                      <li>
                                        <a
                                          @click="
                                            handleAddItem(
                                              element.id,
                                              item.id,
                                              itemSubGroup.id,
                                              itemSubGroup,
                                              event
                                            )
                                          "
                                          class="dropdown-item"
                                          href="#"
                                          >Add Item</a
                                        >
                                      </li>
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href="#"
                                          @click="deleteGroup(itemSubGroup.id)"
                                          >Delete Sub Group</a
                                        >
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </draggable>
                      <div :style="getStyleCategory(1, -1)" class="pt-3 pb-3">
                        <material-button
                          @click="openModalAddCategory()"
                          class="btn btn-sm btn-background"
                        >
                          <i class="material-icons css-icon">addoutlined</i>
                          ADD
                        </material-button>
                      </div>
                    </div>
                    <div
                      class="m-right-menu"
                      style="border: 1px solid #ccc"
                      v-if="categories && categories.length && !isLoadList"
                    >
                      <div
                        class="table-responsive p-0 mt-3"
                        style="
                          margin-bottom: 50px;
                          overflow-x: unset !important;
                        "
                      >
                        <table class="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th>Item no</th>
                              <th v-if="type == 1">Options</th>
                              <th>Item name</th>
                              <th>Price</th>
                              <th v-if="type == 2">Group</th>
                              <th>Image</th>
                              <th></th>
                            </tr>
                          </thead>
                          <draggable
                            v-model="itemByGroupOrSubGroupData"
                            group="itemByGroupOrSubGroupData"
                            @start="onStartDragItem"
                            @end="onEndDragItem"
                            item-key="id"
                            tag="tbody"
                            handle=".handleMoveItem"
                          >
                            <template #item="{ index, element }">
                              <tr
                                :class="{
                                  hovered: hoveredRow === index,
                                }"
                                @mouseenter="hoveredRow = index"
                                @mouseleave="hoveredRow = null"
                                style="cursor: pointer"
                              >
                                <td
                                  :style="{
                                    backgroundColor: element.button_color,
                                  }"
                                  class="w-1"
                                ></td>
                                <td>
                                  <i
                                    class="material-icons-round opacity-10 handleMoveItem"
                                    >drag_indicator_icon</i
                                  >
                                </td>
                                <td class="text-left text-xss w-15">
                                  {{ element.item_no }}
                                </td>
                                <td class="text-left text-xss w-15" v-if="type == 1">
                                  <img
                                    v-if="element.modifier_option_group && element.modifier_option_group.length > 0"
                                    class="me-2"
                                    width="20"
                                    height="20"
                                    src="@/assets/img/option_on_main_icon.jpeg"
                                    alt="logo"
                                    style="margin-left: 20px;"
                                    @click="showPopupUpdateMog(element)"
                                  />
                                  <div v-else style="margin-left: 22px;font-size: 25px;">+</div>
                                </td>
                                <td
                                  class="text-left text-xss w-35"
                                  style="white-space: break-spaces"
                                >
                                  <div class="font-weight-bold cl-22">
                                    {{ element.item_name }}
                                  </div>
                                  <div v-if="element.is_combo">
                                    <div
                                      v-for="(
                                        combo, idx
                                      ) in element.combo_elements"
                                      :key="idx"
                                      class="ps-3"
                                    >
                                      {{ "+ " + combo.name }}
                                    </div>
                                  </div>
                                  <div v-else>
                                    <div v-if="element.description">
                                      {{ element.description }}
                                    </div>
                                  </div>
                                </td>
                                <td class="text-left text-xss w-15">
                                  <div v-if="element.variations.length">
                                    <div
                                      v-for="(
                                        variation, idx
                                      ) in element.variations"
                                      :key="idx"
                                    >
                                      {{
                                        element.item_no +
                                        variation.variation_no +
                                        ": " +
                                        variation.name +
                                        " - " +
                                        variationPriceList[
                                          categoryIdSelect +
                                            "" +
                                            groupIdSelect +
                                            subGroupIdSelect +
                                            element.id +
                                            variation.id
                                        ]
                                      }}
                                    </div>
                                  </div>
                                  <div v-else>
                                    {{ element.price_format }}
                                  </div>
                                </td>
                                <td class="text-left text-xss w-15" v-if="type == 2">
                                  {{ element?.group?.group_name }}
                                </td>
                                <td class="text-left text-xss w-15">
                                  <img
                                    :src="element.image"
                                    alt="Image"
                                    width="70"
                                    height="70"
                                  />
                                </td>
                                <td class="text-left text-xss w-4">
                                  <div class="d-flex align-items-center">
                                    <i
                                      v-if="
                                        element.pivot &&
                                        [1, 3].includes(
                                          element.pivot.schedule_type
                                        )
                                      "
                                      class="material-icons me-2"
                                      data-v-2627f54d=""
                                      style="margin-top: 4px"
                                      >event_busy</i
                                    >
                                    <i
                                      class="fa fa-ellipsis-v text-secondary cursor-pointer dropdownMenuButton"
                                      aria-hidden="true"
                                      id="dropdownMenuButton"
                                      data-bs-toggle="dropdown"
                                    ></i>
                                    <ul
                                      class="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href="#"
                                          @click="
                                            addItemWithIndex(
                                              element.pivot.display_order
                                            )
                                          "
                                        >
                                          <i class="material-icons me-1">add</i>
                                          <span>Add</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href="#"
                                          @click="
                                            editItem(
                                              categoryIdSelect,
                                              element.id,
                                              element.is_combo,
                                              index
                                            )
                                          "
                                        >
                                          <i class="material-icons me-1"
                                            >edit</i
                                          >
                                          <span>Edit</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href="#"
                                          @click="disabledItem(value)"
                                        >
                                          <i class="material-icons me-1"
                                            >not_interested</i
                                          >
                                          <span>Disable</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href="#"
                                          @click="
                                            addScheduleItem(
                                              element.id,
                                              categoryIdSelect
                                            )
                                          "
                                        >
                                          <i class="material-icons me-1"
                                            >event_busy</i
                                          >
                                          <span>Add Schedule</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href="#"
                                          @click="
                                            moveUpDown(
                                              element.pivot.id,
                                              'up',
                                              index
                                            )
                                          "
                                        >
                                          <i class="material-icons me-1"
                                            >arrow_upward</i
                                          >
                                          <span>Move Up</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href="#"
                                          @click="
                                            moveUpDown(
                                              element.pivot.id,
                                              'down',
                                              index
                                            )
                                          "
                                        >
                                          <i class="material-icons me-1"
                                            >arrow_downward</i
                                          >
                                          <span>Move Down</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href="#"
                                          @click="
                                            deattachItem(element.pivot.id)
                                          "
                                        >
                                          <i class="material-icons me-1"
                                            >category</i
                                          >
                                          <span>Deattach</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href="#"
                                          @click="
                                            openModalAddPrinter(
                                              element.id,
                                              'item',
                                              element
                                            )
                                          "
                                        >
                                          <i class="material-icons me-1"
                                            >print</i
                                          >
                                          <span>Add Printer</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </template>
                          </draggable>
                          <tbody
                            v-if="itemByGroupOrSubGroupData.length"
                            style="border-color: #f0f2f5"
                          >
                            <tr>
                              <td colspan="7" class="text-start pt-5">
                                <material-button
                                  @click="selectCategory(categoryIdSelect)"
                                  class="btn btn-sm btn-background"
                                >
                                  <i class="material-icons css-icon"
                                    >addoutlined</i
                                  >
                                  ADD
                                </material-button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="7" class="text-center">
                                No item available
                              </td>
                            </tr>
                            <tr>
                              <td colspan="7" class="text-start pt-5">
                                <material-button
                                  @click="selectCategory(categoryIdSelect)"
                                  class="btn btn-sm btn-background"
                                >
                                  <i class="material-icons css-icon"
                                    >addoutlined</i
                                  >
                                  ADD
                                </material-button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div v-else class="mt-3 category-box">
                    <div
                      class="d-flex align-items-center cursor-pointer"
                      @click="openModalAddCategory()"
                    >
                      <i class="material-icons-round opacity-10">addoutlined</i>
                      <div class="mt-1">Add category</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="type == 3">
                <!-- modifier option group here -->
                <ModifierOptionGroup
                  :dataPreiewItemSelected="this.dataPreiewItemSelected"
                  :reloadModifierOptionGroup="this.reloadModifierOptionGroup"
                  :menu_set_id="this.menuSet.id"
                  @childEvent="handleChildEventModifierOptionGroup"
                  @reload-list-attached-to-item="reloadListAttachedToItem"
                  @delete-item="deleteItemOnModifierOptionGroup"
                  :date_time_now="new Date()"
                  @delete-modifier-option-group="deleteModifierOptionGroup"
                ></ModifierOptionGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
  <material-loading :active="loaderActive" />
  <ListMenusetModal
    :keyReload="keyReloadListMenuset"
    @open-modal-add-menuset="openModalAddMenuset"
    @view-menuset="selectMenuset"
  ></ListMenusetModal>
  <AddMenusetModal
    :keyReload="keyReloadAddMenuset"
    @reload-list-menuset="reloadListMenuset"
    :isEditMenuset="isEditMenuset"
    :menusetId="menuSetIdEditSelect"
  ></AddMenusetModal>
  <AddItemModal
    :keyReload="keyReloadAddItem"
    :isEditItem="isEditItem"
    :menusetId="menuSetIdSelect"
    :categoryId="categoryIdSelect"
    :displayOrder="displayOrder"
    @reload-list-category="getListCategory"
  ></AddItemModal>
  <AddCategoryModal
    :keyReload="keyReloadAddCategory"
    @reload-list-category="reloadListCategory"
    :categoryType="type"
    :isEditCategory="isEditCategory"
    :categoryId="categoryId"
    :setId="menuSetIdSelect"
  ></AddCategoryModal>
  <CreateModalMenuItem
    :isModifiderOptionGroup="isModifiderOptionGroup"
    :modifier_option_group_id="modifier_option_group_id"
    :attachedToItemModifierOptionGroup="attachedToItemModifierOptionGroup"
    :type="type"
    :setId="menuSetIdSelect"
    :categoryId="categoryIdSelect"
    :groupId="groupIdSelect"
    :subGroupId="subGroupIdSelect"
    :keyReload="keyReload"
    @create-new-item="createNewItem"
    @create-new-combo="createNewCombo"
    @reload-list-category="reloadListCategory"
    @reload-list-attached-to-item="reloadListAttachedToItem"
    @add-preiew-item-selected="addPreiewItemSelected"
    :date_time_now="new Date()"
    :itemByGroupOrSubGroupData="itemByGroupOrSubGroupData"
    :itemObject="itemObject"
  ></CreateModalMenuItem>
  <CreateGroupAndSubGroup
    :keyReload="keyReloadModalCreateGroupAndSubGroup"
    :setId="menuSetIdSelect"
    :categoryId="categoryIdSelect"
    :groupId="groupIdSelect"
    :subGroupId="subGroupIdSelect"
    :type="type"
    :isUpdate="isUpdateGroupAndSubGroup"
    :isUpdateSubGroup="isUpdateSubGroup"
    @reload-list-category="reloadListCategory"
    :date_time_now="new Date()"
  ></CreateGroupAndSubGroup>
  <ModalMenuSetSchedule
    :keyReload="keyReloadScheduleModal"
    :setIdSelect="menuSetIdSelect"
    :branchIdSelectEdit="branchIdSelectEdit"
    :categoryIdSelect="categoryIdSelect"
    :itemIdSelect="itemIdSelect"
    @reload-schedule="reloadSchedule"
  ></ModalMenuSetSchedule>
  <ModalConfirmDelete
    message="Are you sure you want to delete?"
    :process-yes="removeItemOnModifierOptionGroup"
  />
  <ModalConfirmGroup
    message="Are you sure you want to delete?"
    :process-yes="removeGroup"
  />
  <ModalConfirmDeleteModifierOptionGroup
    message="Are you sure you want to delete?"
    :process-yes="handleDeleteModifierOptionGroup"
  />
  <ModalConfirmDeleteCategory
    message="Are you sure you want to delete?"
    :process-yes="handleDeleteCategory"
  />
  <ModalConfirmDragMoveCategory
    :message="`Moving item [${draggedCateogry}] position. Keep or discard the change?`"
    :process-yes="handleDragMoveCategory"
    :process-no="handleReturnDragCategory"
  />
  <ModalConfirmDragMoveItem
    :message="`Moving item [${draggedItem}] position. Keep or discard the change?`"
    :process-yes="handleDragMoveItem"
    :process-no="handleReturnDragItem"
  />
  <AddPrinterModal
    :keyReload="keyReloadAddPrinter"
    :categoryId="categoryId"
    :setId="menuSetIdSelect"
    :printerSelectedParam="printerSelected"
    @reload-list-category="reloadListCategory"
    :itemIdSelectedForPrinter="itemIdSelectedForPrinter"
  ></AddPrinterModal>
  <UpdateModifierOptionGroupForItem
    :keyReload="keyReloadUpdateModifierOptionGroupForItem"
    :item="itemUpdateModifierOptionGroupForItem"
    :setId="menuSetIdSelect"
    @reload-list-category="reloadListCategory"
    :categoryId="categoryIdSelect"
  ></UpdateModifierOptionGroupForItem>
  <form :action="`${actionDownload}/merchant/menu-set/export`" method="POST">
    <input type="hidden" name="merchantId" :value="merchantIdExport" />
    <input type="hidden" name="merchant_code" :value="merchantCode" />
    <input type="hidden" name="token" :value="token" />
    <input type="hidden" name="menuset_id" :value="menuSetIdSelect" />
    <!-- <input type="hidden" name="type" :value="type" /> -->
    <input id="exportMenu" type="submit" class="d-none" />
  </form>
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/js/dist/modal";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import $ from "jquery";
import AddCategoryModal from "@/components/AddCategory.vue";
import CreateGroupAndSubGroup from "@/components/CreateGroupAndSubGroup.vue";
import CreateModalMenuItem from "@/components/CreateModalMenuItem.vue";
import MenusetService from "../../services/product/menuset.service";
import Multiselect from "@vueform/multiselect";
import ItemService from "../../services/product/item.service";
import ModalConfirmDelete from "@/components/ModalConfirmDelete.vue";
import ModalConfirmGroup from "@/components/ModalConfirmGroup.vue";
import ModifierOptionGroup from "./ModifierOptionGroup.vue";
import ModifierOptionGroupService from "../../services/product/modifierOptionGroup";
import ModifierGroupService from "../../services/product/modifierGroup.service";
import ModalConfirmDeleteModifierOptionGroup from "@/components/ModalConfirmDeleteModifierOptionGroup.vue";
import ModalMenuSetSchedule from "@/components/ModalMenuSetSchedule.vue";
import draggable from "vuedraggable";
import ModalConfirmDeleteCategory from "@/components/ModalConfirmDeleteCategory.vue";
import ModalConfirmDragMoveCategory from "@/components/ModalConfirmDragMoveCategory.vue";
import ModalConfirmDragMoveItem from "@/components/ModalConfirmDragMoveItem.vue";
import AddPrinterModal from "@/components/AddPrinterModal.vue";
import AddMenusetModal from "@/components/AddMenuset.vue";
import ListMenusetModal from "@/components/ListMenuset.vue";
import AddItemModal from "@/components/AddItem.vue";
import TokenService from "../../services/token.service";
import UpdateModifierOptionGroupForItem from "@/components/UpdateModifierOptionGroupForItem.vue";

export default {
  name: "menuset",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    AddCategoryModal,
    CreateModalMenuItem,
    Multiselect,
    ModifierOptionGroup,
    ModalConfirmDelete,
    CreateGroupAndSubGroup,
    ModalConfirmGroup,
    ModalConfirmDeleteModifierOptionGroup,
    ModalMenuSetSchedule,
    draggable,
    ModalConfirmDeleteCategory,
    ModalConfirmDragMoveCategory,
    ModalConfirmDragMoveItem,
    AddPrinterModal,
    AddMenusetModal,
    ListMenusetModal,
    AddItemModal,
    UpdateModifierOptionGroupForItem
  },
  data() {
    return {
      token: TokenService.getLocalAccessToken(),
      actionDownload: process.env.VUE_APP_BASE_URL,
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCode: localStorage.getItem("MERCHANT_CODE", ""),
      isLoadData: false,
      snackbar: null,
      message: "",
      loaderActive: false,
      menusets: [],
      menuSet: {
        id: 0,
        name: "",
        description: "",
        image: null,
        // branchIdSelect: [],
        priceList: [],
      },
      isShowCategory: false,
      categories: [],
      originalCategories: [],
      draggedCateogry: "",
      isCloseModal: false,
      originalItems: [],
      draggedItem: "",
      variationPriceList: [],
      type: this.$route.query.type ?? 1,
      categoryId: 0,
      categoryIdSelect: 0,
      groupIdSelect: 0,
      subGroupIdSelect: 0,
      itemIdSelect: 0,
      itemSelectInfo: {},
      menuSetIdSelect: this.$route.query.set_id ?? 0,
      menuSetIdSelectBefore: this.menuSetIdSelect,
      menuSetIdEditSelect: 0,
      keyReload: "key-reload",
      keyReloadListMenuset: "key-reload-list-menuset",
      keyReloadAddItem: "key-reload-add-item",
      keyReloadAddMenuset: "key-reload-add-menuset",
      keyReloadAddCategory: "key-reload-add-category",
      keyReloadScheduleModal: "key-reload-schedule-modal",
      keyReloadModalCreateGroupAndSubGroup:
        "key-reload-modal-create-group-and-sub-group",
      isEditMenuset: false,
      isEditCategory: false,
      isEditItem: 0,
      branchIdSelectDelete: 0,
      branchIdSelectEdit: 0,
      // branches: [],
      scheduleList: [],
      isUpdateGroupAndSubGroup: false,
      isUpdateSubGroup: false,
      itemScheduleList: [],
      priceListDisplay: [],
      isModifiderOptionGroup: false,
      modifier_option_group_id: 0,
      reloadModifierOptionGroup: "",
      itemIdInModifierOptionGroupId: 0,
      dataPreiewItemSelected: [],
      categorySelected: null,
      group_id_to_delete: 0,
      itemByGroupOrSubGroupData: [],
      attachedToItemModifierOptionGroup: [],
      groupIdSelectToHilight: 0,
      subGroupIdSelectToHilight: 0,
      categoryIdSelectToHilight: 0,
      idModifierOptionGroupId: 0,
      hoveredRow: null,
      categoryHoveredRow: null,
      drag: false,
      idCategoryId: 0,
      printerSelected: [],
      keyReloadAddPrinter: "key-reload-add-printer",
      itemIdSelectedForPrinter: 0,
      requestFrom: "",
      displayOrder: 0,
      itemObject: null,
      keyReloadUpdateModifierOptionGroupForItem: "key-reload-update-modifier-option-group-for-item",
      itemUpdateModifierOptionGroupForItem: null
    };
  },
  async created() {
    await this.changeTab(this.type);
    // this.getListCategory();
    await this.getMenusets();
    if (this.menuSetIdSelect) {
      await this.selectMenuset(this.menuSetIdSelect, true);

      this.categoryIdSelect = this.$route.query.category_id ?? 0;
      if (this.categoryIdSelect) {
        let category = this.categories.find(
          (item) => item.id == this.categoryIdSelect
        );
        this.groupIdSelect = this.$route.query.group_id ?? 0;

        if (category) {
          if (this.groupIdSelect) {
            let group = category.groups.find(
              (item) => item.id == this.groupIdSelect
            );
            this.subGroupIdSelect = this.$route.query.sub_group_id ?? 0;

            if (group) {
              if (this.subGroupIdSelect) {
                let subgroup = group.sub_groups.find(
                  (item) => item.id == this.subGroupIdSelect
                );

                if (subgroup) {
                  this.itemBySubGroup(subgroup);
                }
              } else {
                this.itemByGroup(group);
              }
            }
          } else {
            this.itemByCategory(category);
          }
        }
      }
    }
  },
  mounted() {
    this.getPriceList();
  },
  methods: {
    async changeTab(type) {
      this.categorySelected = [];
      this.itemByGroupOrSubGroupData = [];
      this.attachedToItemModifierOptionGroup = [];
      this.isModifiderOptionGroup = false;
      this.type = type;
      this.categoryIdSelectToHilight = 0;
      this.groupIdSelectToHilight = 0;
      this.subGroupIdSelectToHilight = 0;
      this.itemObject = null;
      $("#general-tab .nav-link").removeClass("active");
      $("#nav_item_" + type).addClass("active");
      await this.getListCategory();
    },

    async selectMenuset(menuSetId) {
      if (menuSetId == 999999) {
        setTimeout(() => {
          this.menuSetIdSelect = this.menuSetIdSelectBefore;
        }, 500);
        this.openModalAddMenuset();
      } else {
        if (menuSetId != 0 && menuSetId == this.menuSetIdSelectBefore) {
          return;
        }

        this.clearData();
        this.menuSetIdSelect = menuSetId;
        this.menuSetIdSelectBefore = menuSetId;
        await this.getMenuDetail();
        await this.getListCategory();
      }
    },

    async getMenuDetail() {
      this.isLoadData = false;
      // if (this.menuSetIdSelect) {
      this.showLoader();
      await MenusetService.menusetDetail(this.menuSetIdSelect).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.menuSet = response.data.data;
            this.isShowCategory = true;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
      // }
    },

    async getMenusets(loading = true) {
      if (loading) {
        this.showLoader();
      }

      var dataForm = {
        type: "select",
      };
      await MenusetService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.menusets = response.data.data;
            this.menusets.push({
              value: 999999,
              label: "+ Add New",
              html: `<a
                class="text-decoration-underline"
                style="color: #007bff;"
                >+ Add New</a
              >`,
            });
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    disabledItem(item) {
      this.showLoader();
      var dataForm = {
        id: item.id,
        value: item.is_disabled,
      };
      ItemService.handleEnableOrDisable(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.getListCategory();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
      this.hideLoader();
    },

    getPriceList(setId = 0) {
      this.showLoader();
      var dataForm = {
        set_id: setId ? setId : this.$route.params.id,
      };

      MenusetService.getPriceList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.menuSet.priceList = response.data.data.price_list;
            this.priceListDisplay = response.data.data.price_list_display;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    clearData() {
      this.menuSetIdSelect = 0;
      this.categoryIdSelect = 0;
      this.groupIdSelect = 0;
      this.subGroupIdSelect = 0;
      this.itemIdSelect = 0;
      this.type = this.$route.query.type ?? 1;
      this.categories = [];
      this.itemByGroupOrSubGroupData = [];
      this.attachedToItemModifierOptionGroup = [];
      this.groupIdSelectToHilight = 0;
      this.subGroupIdSelectToHilight = 0;
      this.categoryIdSelectToHilight = 0;
      this.idModifierOptionGroupId = 0;
      this.isModifiderOptionGroup = false;
      this.categorySelected = [];
      this.isLoadData = false;
      this.isShowCategory = false;
      this.idCategoryId = 0;
      this.itemObject = null;
    },

    async getListCategory() {
      this.categories = [];
      this.showLoader();
      var dataForm = {
        set_id: this.menuSetIdSelect,
        type: this.type,
      };

      await MenusetService.getListCategory(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.categories = response.data.data.categories;
            this.variationPriceList = response.data.data.variation_price_list;

            let targetItems = [];
            this.categories.forEach((category, index) => {
              if (index == 0 && !this.categoryIdSelectToHilight) {
                this.categoryIdSelectToHilight = category.id;
                this.categoryIdSelect = category.id;
              }
              if (category.id === this.categoryIdSelectToHilight) {
                targetItems = category.items;
              }
              category.groups.forEach((group) => {
                if (group.id === this.groupIdSelectToHilight) {
                  targetItems = group.items;
                } else {
                  group.sub_groups.forEach((subGroup) => {
                    if (subGroup.id === this.subGroupIdSelectToHilight) {
                      targetItems = subGroup.sub_groupitems;
                    }
                  });
                }
              });
            });

            if (targetItems) {
              this.itemByGroupOrSubGroupData = targetItems;
            }
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    moveUpDown(
      categoryItemId,
      type,
      itemIndex,
      isCategory = false,
      categoryIndex = 0
    ) {
      if (type == "up" && itemIndex == 0) {
        return;
      }

      if (type == "down") {
        if (isCategory) {
          if (this.categories[categoryIndex].items.length == itemIndex) {
            return;
          }
        } else {
          if (this.itemByGroupOrSubGroupData.length == itemIndex + 1) {
            return;
          }
        }
      }

      var dataForm = {
        category_item_id: categoryItemId,
        type: type,
      };

      ItemService.moveUpDown(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            let newItemIndex = itemIndex;
            if (type == "up") {
              newItemIndex -= 1;
            } else {
              newItemIndex += 1;
            }

            if (isCategory) {
              let itemChange = this.categories[categoryIndex].items.splice(
                itemIndex,
                1
              );
              this.categories[categoryIndex].items.splice(
                newItemIndex,
                0,
                itemChange
              );
            } else {
              let itemChange = this.itemByGroupOrSubGroupData[itemIndex];
              this.itemByGroupOrSubGroupData.splice(itemIndex, 1);
              this.itemByGroupOrSubGroupData.splice(
                newItemIndex,
                0,
                itemChange
              );
            }
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    deattachItem(id) {
      MenusetService.deattachItem(id).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.reloadListCategory("Deattach item success");
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    reloadListCategory(mess) {
      this.categoryId = 0;
      this.snackbar = "success";
      this.message = mess;
      this.setTimeOutNotification();
      this.getListCategory();
    },

    selectCategory(id) {
      this.categoryIdSelect = id;
      this.displayOrder = 0;
      this.keyReload = Math.random();

      $("#show-modal-select-item").click();
    },

    addItemWithIndex(index) {
      this.displayOrder = index;
      $("#show-modal-select-item").click();
    },

    async editItem(categoryId, itemId, isCombo = 0, index) {
      console.log(categoryId, itemId, isCombo, index);
      this.isEditItem = itemId;
      this.keyReloadAddItem = Math.random();
      $("#show-modal-add-item").click();
    },

    reloadListMenuset(type = "add") {
      $("#hide-modal-add-menuset").click();

      if (type == "add") {
        this.getMenusets(false);
      }

      if (this.requestFrom == "list") {
        this.keyReloadListMenuset = Math.random();
        $("#show-modal-list-menuset").click();
      }

      this.requestFrom = "";
    },

    openModalListMenuset() {
      this.keyReloadListMenuset = Math.random();
      $("#show-modal-list-menuset").click();
    },

    openModalAddMenuset(type = "add", id = 0, from = "") {
      console.log("id", id);

      this.keyReloadAddMenuset = Math.random();
      this.requestFrom = from;
      if (type == "add") {
        this.isEditMenuset = false;
      } else {
        this.menuSetIdEditSelect = id ? id : this.menuSetIdSelect;
        this.isEditMenuset = true;
      }
      $("#show-modal-add-menuset").click();
    },

    openModalAddCategory(id = 0, type = "add") {
      this.keyReloadAddCategory = Math.random();
      this.categoryId = id;
      if (type == "add") {
        this.isEditCategory = false;
      } else {
        this.isEditCategory = true;
      }
      $("#show-modal-add-category").click();
    },

    createNewItem() {
      this.isEditItem = 0;
      this.keyReloadAddItem = Math.random();
      $("#show-modal-add-item").click();
    },

    createNewCombo() {
      // this.keyReloadAddItem = Math.random();
      // $("#show-modal-add-item").click();
    },

    addScheduleItem(itemId, categoryId) {
      this.branchIdSelectEdit = 0;
      this.categoryIdSelect = categoryId;
      this.itemIdSelect = itemId;
      this.keyReloadScheduleModal = Math.random();
      $("#show-modal-menuset-schedule").click();
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    confirmDelete(branchId) {
      this.branchIdSelectDelete = branchId;
      $(".btn-confirm-modal").click();
    },

    handleChildEventModifierOptionGroup(data) {
      // console.log(data);
      this.keyReload = Math.random();
      this.isModifiderOptionGroup = true;
      this.modifier_option_group_id = data.modifier_option_group_id;
      this.attachedToItemModifierOptionGroup = data.attachedToItem;
      this.itemObject = data.itemObject;
      if (data && data.itemObject) {
        $(".btn-select-item-for-modifier-option-group-modal").click();
      } else {
        $(".btn-select-item-modal").click();
      }
    },

    reloadListAttachedToItem(mess) {
      this.reloadModifierOptionGroup = mess;
    },

    deleteItemOnModifierOptionGroup(item_id) {
      this.itemIdInModifierOptionGroupId = item_id;
      $(".btn-confirm-modal-delete").click();
    },

    removeItemOnModifierOptionGroup() {
      $(".btn-close-modal").click();
      this.showLoader();
      ModifierOptionGroupService.deleteItem(
        this.itemIdInModifierOptionGroupId
      ).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.reloadModifierOptionGroup = "Delete Success";
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    addPreiewItemSelected(data) {
      console.log(data);
      this.dataPreiewItemSelected = data;
    },

    selectedCategory(category_id) {
      const foundCategory = this.categories.find(
        (obj) => obj.id === category_id
      );
      this.categorySelected = foundCategory;
    },

    openModalGroup(category_id) {
      // this.categoryIdSelect = this.categorySelected.id;
      this.categoryIdSelect = category_id;
      this.isUpdateGroupAndSubGroup = false;
      this.isUpdateSubGroup = false;
      this.groupIdSelect = 0;
      this.subGroupIdSelect = 0;

      $("#show-modal-create-group-and-sub-group").click();
    },
    openModalEditGroup(group_id, category_id) {
      // this.categoryIdSelect = this.categorySelected.id;
      this.categoryIdSelect = category_id;
      this.isUpdateGroupAndSubGroup = true;
      this.isUpdateSubGroup = false;
      this.groupIdSelect = group_id;
      this.subGroupIdSelect = 0;

      $("#show-modal-create-group-and-sub-group").click();
    },
    openModalAddSubGroup(group_id, category_id) {
      // this.categoryIdSelect = this.categorySelected.id;
      this.categoryIdSelect = category_id;
      this.isUpdateGroupAndSubGroup = false;
      this.isUpdateSubGroup = false;
      this.groupIdSelect = group_id;
      this.subGroupIdSelect = 0;

      $("#show-modal-create-group-and-sub-group").click();
    },
    openModalEditSubGroup(group_id, category_id) {
      // this.categoryIdSelect = this.categorySelected.id;
      this.categoryIdSelect = category_id;
      this.isUpdateGroupAndSubGroup = false;
      this.isUpdateSubGroup = true;
      this.groupIdSelect = 0;
      this.subGroupIdSelect = group_id;

      $("#show-modal-create-group-and-sub-group").click();
    },
    deleteGroup(group_id) {
      this.group_id_to_delete = group_id;
      $(".btn-confirm-group-modal").click();
    },
    removeGroup() {
      $(".btn-close-modal").click();
      this.showLoader();
      ModifierGroupService.deleteGroup(this.group_id_to_delete).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.snackbar = "success";
            this.message = "Delete Successfully!";
            this.getListCategory();
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    handleAddItem(category_id, group_id, sub_group_id, item, event) {
      if (event) {
        event.preventdefault();
      }
      // console.log(item);
      if (item && item.sub_groupitems) {
        // console.log("sub");
        this.itemBySubGroup(item);
      } else {
        // console.log("group");
        this.itemByGroup(item);
      }
      // this.itemByGroupOrSubGroupData = item.sub_groupitems ? item.sub_groupitems : item.items;
      this.groupIdSelect = group_id;
      this.subGroupIdSelect = sub_group_id;
      this.selectCategory(category_id);
    },
    itemByCategory(item) {
      this.closeAllDropdowns();
      this.categoryIdSelect = item.id;
      this.groupIdSelect = 0;
      this.subGroupIdSelect = 0;
      this.categoryIdSelectToHilight = item.id;
      this.groupIdSelectToHilight = 0;
      this.subGroupIdSelectToHilight = 0;
      this.itemByGroupOrSubGroupData = item.items;
    },
    itemByGroup(item) {
      this.closeAllDropdowns();
      this.categoryIdSelect = item.category_id;
      this.groupIdSelect = item.id;
      this.subGroupIdSelect = 0;
      this.categoryIdSelectToHilight = 0;
      this.groupIdSelectToHilight = item.id;
      this.subGroupIdSelectToHilight = 0;
      this.itemByGroupOrSubGroupData = item.items;
    },
    itemBySubGroup(item) {
      this.closeAllDropdowns();
      this.categoryIdSelect = item.category_id;
      this.groupIdSelect = item.parent_id;
      this.subGroupIdSelect = item.id;
      this.categoryIdSelectToHilight = 0;
      this.groupIdSelectToHilight = 0;
      this.subGroupIdSelectToHilight = item.id;
      this.itemByGroupOrSubGroupData = item.sub_groupitems;
    },
    getStyleCategory(index, categoryId) {
      let isHighlighted = this.categoryIdSelectToHilight === categoryId;

      if (this.groupIdSelectToHilight || this.subGroupIdSelectToHilight) {
        isHighlighted = false;
      }

      return {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        width: "100%",
        border: "#ccc",
        background: isHighlighted ? "#1871bd !important" : "white",
        color: isHighlighted ? "white" : "black",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        borderTop: index == 0 ? "1px solid #ccc" : "unset",
        borderBottom: "1px solid #ccc",
        borderLeft: "1px solid #ccc",
        borderRight: "1px solid #ccc",
        cursor: "pointer",
      };
    },

    getStyleGroup(itemId) {
      let isHighlighted = this.groupIdSelectToHilight === itemId;

      if (this.subGroupIdSelectToHilight) {
        isHighlighted = false;
      }

      return {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        width: "100%",
        border: "#ccc",
        background: isHighlighted ? "#1871bd" : "white",
        color: isHighlighted ? "white" : "black",
        paddingLeft: "5px",
        paddingRight: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        borderBottom: "1px solid #ccc",
        borderLeft: "1px solid #ccc",
        borderRight: "1px solid #ccc",
        cursor: "pointer",
      };
    },

    getStyleSubGroup(itemId) {
      const isHighlighted = this.subGroupIdSelectToHilight === itemId;
      return {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        width: "100%",
        paddingRight: "10px",
        paddingLeft: "15px",
        paddingTop: "5px",
        paddingBottom: "5px",
        background: isHighlighted ? "#1871bd" : "white",
        color: isHighlighted ? "white" : "black",
        cursor: "pointer",
      };
    },

    closeAllDropdowns() {
      const dropdownMenus = document.querySelectorAll("ul.dropdown-menu.show");
      dropdownMenus.forEach((menu) => {
        menu.classList.remove("show");
        menu.classList.add("hide");
      });
    },

    deleteModifierOptionGroup(item_id) {
      this.idModifierOptionGroupId = item_id;
      $(".btn-confirm-modal-delete-modifier-option-group").click();
    },

    handleDeleteModifierOptionGroup() {
      $(".btn-close-modal").click();
      this.showLoader();
      ModifierOptionGroupService.delete(this.idModifierOptionGroupId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.reloadModifierOptionGroup = "Delete Success";
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    onEndDragCategory() {
      if (this.categories && this.categories.length > 1) {
        $(".btn-confirm-modal-drag-move-category").click();
      }
    },
    onStartDragCategory(event) {
      if (this.categories && this.categories.length > 1) {
        const currentItem =
          this.categories[event.oldIndex] == undefined
            ? ""
            : this.categories[event.oldIndex];
        this.draggedCateogry = currentItem.second_name;
        this.originalCategories = [...this.categories];
      } else {
        this.draggedCateogry = "";
        this.originalCategories = [];
      }
      this.drag = true;
    },
    handleReturnDragCategory() {
      if (this.isCloseModal) {
        return true;
      }
      if (this.categories && this.categories.length > 1) {
        this.showLoader();
        this.categories = [...this.originalCategories];
      }
      this.hideLoader();
      this.originalCategories = [];
    },
    handleDragMoveCategory() {
      if (this.categories && this.categories.length > 1) {
        this.isCloseModal = true;
        $(".btn-close-modal").click();
        this.isCloseModal = false;
        this.showLoader();
        var dataForm = {
          orderCategories: this.categories,
        };
        MenusetService.updateCategoriesOrder(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              console.log(response.data);
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.originalCategories = [];
            this.draggedCateogry = "";
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
            this.originalCategories = [];
            this.draggedCateogry = "";
          }
        );
      }
      this.originalCategories = [];
      this.isCloseModal = false;
    },

    onEndDragItem() {
      if (
        this.itemByGroupOrSubGroupData &&
        this.itemByGroupOrSubGroupData.length > 1
      ) {
        $(".btn-confirm-modal-drag-move-item").click();
      }
    },
    onStartDragItem(event) {
      if (
        this.itemByGroupOrSubGroupData &&
        this.itemByGroupOrSubGroupData.length > 1
      ) {
        const currentItem =
          this.itemByGroupOrSubGroupData[event.oldIndex] == undefined
            ? ""
            : this.itemByGroupOrSubGroupData[event.oldIndex];
        this.draggedItem = currentItem.item_name;
        this.originalItems = [...this.itemByGroupOrSubGroupData];
      } else {
        this.draggedItem = "";
        this.originalItems = [];
      }
      this.drag = true;
    },
    handleReturnDragItem() {
      if (this.isCloseModal) {
        return true;
      }
      if (
        this.itemByGroupOrSubGroupData &&
        this.itemByGroupOrSubGroupData.length > 1
      ) {
        this.showLoader();
        this.itemByGroupOrSubGroupData = [...this.originalItems];
      }
      this.hideLoader();
      this.originalItems = [];
    },
    handleDragMoveItem() {
      if (
        this.itemByGroupOrSubGroupData &&
        this.itemByGroupOrSubGroupData.length > 1
      ) {
        this.isCloseModal = true;
        $(".btn-close-modal").click();
        this.isCloseModal = false;
        this.showLoader();
        var dataForm = {
          category_id: this.categoryIdSelect,
          group_id: this.groupIdSelect,
          sub_group_id: this.subGroupIdSelect,
          itemIds: this.itemByGroupOrSubGroupData.map((value) => {
            return value.id;
          }),
        };
        MenusetService.updateItemsOrder(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              console.log("success");
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.originalItems = [];
            this.draggedItem = "";
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
            this.originalItems = [];
            this.draggedItem = "";
          }
        );
      }
      this.originalItems = [];
      this.isCloseModal = false;
    },
    reloadSchedule() {
      this.getListCategory();
    },
    deleteCategory(item_id) {
      this.idCategoryId = item_id;
      $(".btn-confirm-modal-delete-category").click();
    },
    handleDeleteCategory() {
      $(".btn-close-modal").click();
      this.showLoader();
      MenusetService.deleteCategory({ id: this.idCategoryId }).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.getListCategory();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    openModalAddPrinter(id = 0, type, object) {
      this.keyReloadAddPrinter = Math.random();
      if (type == "category") {
        this.categoryId = id;
        this.printerSelected = object.printerSelect;
      } else {
        this.itemIdSelectedForPrinter = id;
        this.printerSelected = object.printerSelect;
      }
      $("#show-modal-add-printer").click();
    },

    exportMenu() {
      if (this.menuSetIdSelect) {
        $("#exportMenu").click();
      }
    },
    showPopupUpdateMog(item) {
      this.keyReloadUpdateModifierOptionGroupForItem = Math.random();
      this.itemUpdateModifierOptionGroupForItem = item;
      $("#show-modal-update-modifier-option-group-for-item").click();
    }
  },
};
</script>
<style scoped>
.page-header .container {
  color: #222 !important;
}

.dowload-img {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 80%;
  height: 350px;
}

.add-category {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-35 {
  width: 35%;
}

.w-65 {
  width: 65%;
}

.css-icon-upload {
  font-size: 120px !important;
  margin-top: 4rem;
}

.css-icon {
  position: absolute;
  left: 17px !important;
  font-size: 18px !important;
  top: 7px !important;
}

.cate-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d5d6d8;
  background: #ebecee !important;
  padding: 15px 0;
  border-radius: 5px;
}

.category-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border: 1px solid #d5d6d8;
  color: #133067;
  border-radius: 5px;
}

.category-box:last-child {
  margin-bottom: 50px;
}

.cate-card {
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
}

.cate-card > div div:last-child {
  white-space: nowrap;
}

.material-icons-round {
  width: 30px;
}

.cl-22 {
  color: #222 !important;
}

.mw-185px {
  min-width: 185px;
}

.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}

.schedule-list {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.schedule-list::-webkit-scrollbar {
  width: 7px;
}

.cate-list > div:first-child > div::-webkit-scrollbar {
  height: 7px;
}

.schedule-list::-webkit-scrollbar-track,
.cate-list > div:first-child > div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.schedule-list::-webkit-scrollbar-thumb,
.cate-list > div:first-child > div::-webkit-scrollbar-thumb {
  background: #888;
}

.schedule-list::-webkit-scrollbar-thumb:hover,
.cate-list > div:first-child > div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown-menu {
  border: 1px solid #d5d6d8;
  min-width: 9rem;
}
.cursor-pointer {
  cursor: pointer !important;
}

.unset-ov {
  overflow: unset !important;
}

.table-responsive thead tr th {
  padding: 0.5rem !important;
}

.activeLayout {
  background: white;
  align-items: center !important;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
}

.inActiveLayout {
  /* background: gray; */
  align-items: center !important;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
}

.tag-a-custom {
  color: #007bff !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.s-menuset .multiselect {
  margin: 0 !important;
  max-width: 500px !important;
}

.page-header .container {
  z-index: unset !important;
}

.page-header .mask {
  z-index: -1 !important;
}

.m-left-menu {
  width: 280px !important;
  min-width: 280px !important;
  margin-right: 24px;
}

.m-right-menu {
  width: 100%;
}

.hovered {
  background-color: #f5f5f5 !important;
}

#general-tab .nav-item {
  width: 25%;
  text-align: center;
}

.selected {
  background-color: #f5f5f5;
}

.dropdownMenuButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 30px;
}

.fa-ellipsis-v:before {
  margin-top: 6px;
}

.dropdown-item {
  display: flex;
  align-items: center;
}
</style>
