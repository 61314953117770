<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_dashboard">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          :collapseRef="`/home`"
          :navText="$t('Dashboard')"
        >
          <template v-slot:icon>
            <i
              class="material-icons-round opacity-10 fs-5"
              @click="toggleSidebar()"
              >home</i
            >
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_pos_simulator">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          :collapseRef="`/test`"
          :navText="$t('POS Simulator')"
        >
          <template v-slot:icon>
            <i
              class="material-icons-round opacity-10 fs-5"
              @click="toggleSidebar()"
              >shopping_cart_checkout</i
            >
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_setting">
        <sidenav-collapse-item :text="$t('Setting')" miniIcon="settings" sidenavKey="1">
          <template v-slot:nav-child-item>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_general">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/general-setting`"
                :navText="$t('General')"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_cashback">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/cashback-setting`"
                :navText="$t('Cashback')"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_point">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/point-setting`"
                :navText="$t('Point')"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_discount">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/discounts`"
                :navText="$t('Discount')"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_voucher_set">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/vouchers`"
                :navText="$t('VoucherSet')"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_voucher_set">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/vouchers-bundle`"
                :navText="$t('Voucher_Bundle')"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/bulk-voucher-issuance`"
                :navText="$t('Bulk Voucher Issuance')"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_tier">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/tier-setting`"
                :navText="$t('Tier')"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_slides">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/slide-setting`"
                :navText="$t('Slides')"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_branches">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/branches`"
                :navText="$t('Virtual_Branches')"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_top_up">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/top-up`"
                :navText="$t('TopUp')"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_sms">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/sms-setup`"
                :navText="$t('SMS')"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_sms">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/email-setup`"
                :navText="$t('Email')"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
          </template>
        </sidenav-collapse-item>
      </li>
      <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_reports">
        <sidenav-collapse-item :text="$t('Report')" miniIcon="dashboard" sidenavKey="2">
          <template v-slot:nav-child-item>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/dashboard-reports-online-ordering`"
                :navText="$t('PaidOnlineOrders')"
                >
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_pos_transaction">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/dashboard-reports-pos-transaction`"
                :navText="$t('POSTransaction')"
                >
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_voucher_redemption">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/dashboard-reports`"
                :navText="$t('VoucherRedemption')"
              >
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_slide_show">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/dashboard-reports-slides-show`"
                :navText="$t('SlideShow')"
              >
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_topup">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/dashboard-reports-top-up`"
                :navText="$t('TopupReports')"
                >
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_void_topup">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/dashboard-reports-void-top-up`"
                :navText="$t('VoidTopupReports')"
                >
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/dashboard-reports-paid-membership`"
                :navText="$t('PaidMembership')"
                >
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/dashboard-reports-order-item`"
                :navText="$t('OrderItem')"
                >
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/dashboard-reports-wallet`"
                :navText="$t('WalletReports')"
                >
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/dashboard-reports-donation`"
                :navText="$t('DonationReports')"
                >
              </sidenav-collapse>
            </li>
          </template>
        </sidenav-collapse-item>
      </li>
      <li class="nav-item">
        <sidenav-collapse-item text="Analytics" miniIcon="dashboard" sidenavKey="7">
          <template v-slot:nav-child-item>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/analytics/daily-collections`"
                navText="Daily Collections"
              >
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/analytics/collection-by-branch`"
                navText="Collection By Branch"
              >
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/analytics/item-sales-by-branch`"
                navText="Item Sales By Branch"
              >
              </sidenav-collapse>
            </li>
          </template>
        </sidenav-collapse-item>
      </li>
      <li class="nav-item" v-if="dataManagerModule.merchant_code == 'svkt'">
        <sidenav-collapse-item :text="$t('Booking')" miniIcon="calendar_month" sidenavKey="6">
          <template v-slot:nav-child-item>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/booking/venue`"
                :navText="$t('ServiceSlotSetting')"
              >
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/booking/management`"
                :navText="$t('BookingManagement')"
              >
              </sidenav-collapse>
            </li>
          </template>
        </sidenav-collapse-item>
      </li>
      <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_marketing">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          :collapseRef="`/campaigns`"
          :navText="$t('Marketing')"
        >
          <template v-slot:icon>
            <i
              class="material-icons-round opacity-10 fs-5"
              @click="toggleSidebar()"
              >campaign</i
            >
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse-item :text="$t('Menu')" miniIcon="menu" sidenavKey="5">
          <template v-slot:nav-child-item>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/menu-set`"
                :navText="$t('Menu Set')"
              >
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/item-list`"
                :navText="$t('Item List')"
              >
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/report-group`"
                :navText="$t('Report Group & Type')"
              >
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/menu-set/printers`"
                :navText="$t('Printers')"
              >
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/menu-set/export`"
                :navText="$t('Export')"
              >
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/menu-set/import`"
                :navText="$t('Import')"
              >
              </sidenav-collapse>
            </li>
          </template>
        </sidenav-collapse-item>
      </li>
      <li class="nav-item" v-if="nodeEnv === 'staging' && dataManagerModule && dataManagerModule.is_products">
        <sidenav-collapse-item :text="$t('Product')" miniIcon="storefront" sidenavKey="3">
          <template v-slot:nav-child-item>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_product_list">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/products`"
                navText="Product List"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_product_group_list">
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                :collapseRef="`/group`"
                navText="Product Group List"
              >
                <template v-slot:icon>
                  <i
                    class="material-icons-round opacity-10 fs-5"
                    @click="toggleSidebar()"
                  ></i>
                </template>
              </sidenav-collapse>
            </li>
          </template>
        </sidenav-collapse-item>
      </li>
      <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_loyalty_members">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          :collapseRef="`/members`"
          :navText="$t('Loyalty Members')"
        >
          <template v-slot:icon>
            <i
              class="material-icons-round opacity-10 fs-5"
              @click="toggleSidebar()"
              >groups</i
            >
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="dataManagerModule && dataManagerModule.is_payment">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          :collapseRef="`/payments`"
          :navText="$t('Payment')"
        >
          <template v-slot:icon>
            <i
              class="material-icons-round opacity-10 fs-5"
              @click="toggleSidebar()"
              >payments</i
            >
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="nodeEnv === 'staging' && dataManagerModule && dataManagerModule.is_subscription">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          :collapseRef="`/subscriptions`"
          :navText="$t('Subscription')"
        >
          <template v-slot:icon>
            <i
              class="material-icons-round opacity-10 fs-5"
              @click="toggleSidebar()"
              >subscriptions</i
            >
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse-item :text="$t('language')" miniIcon="language" sidenavKey="4">
          <template v-slot:nav-child-item>
            <li class="nav-item">
              <a @click="changeLanguage('en')" href="javascript:void(0)" class="nav-link" data-bs-toggle="" aria-controls="" aria-expanded="false">
                <span class="nav-link-text ms-1">English</span>
              </a>
            </li>
            <li class="nav-item">
              <a @click="changeLanguage('cn')" href="javascript:void(0)" class="nav-link" data-bs-toggle="" aria-controls="" aria-expanded="false">
                <span class="nav-link-text ms-1">Chinese</span>
              </a>
            </li>
          </template>
        </sidenav-collapse-item>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          :collapseRef="`/sign-in`"
          @click="logOut()"
          :navText="$t('logout')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">login</i>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import { mapMutations } from "vuex";
import ModuleService from "../../services/module.service";
import EventBus from "vue3-eventbus";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  components: {
    SidenavCollapse,
    SidenavCollapseItem,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      merchantCode: "",
      nodeEnv: process.env.VUE_APP_NODE_ENV,
      dataManagerModule: []
    };
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
    this.getModule();
    EventBus.on("ReloadSidenav", () => {
      this.getModule();
    });
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    toggleSidebar() {
      this.navbarMinimize();
    },
    logOut() {
      this.$store.dispatch("auth/logout");
    },
    changeLanguage(language) {
      // const savedLocale = localStorage.getItem('locale')
      // if (savedLocale && savedLocale === process.env.VUE_APP_LANG_EN) {
      //   localStorage.setItem('locale', language)
      //   this.$i18n.locale = language
      // } else {
      //   localStorage.setItem('locale', process.env.VUE_APP_LANG_EN)
      //   this.$i18n.locale = process.env.VUE_APP_LANG_EN
      // }
      localStorage.setItem('locale', language)
      this.$i18n.locale = language
    },
    getModule() {
      ModuleService.getModule().then(
        (response) => {
          if (response.data.result.isSuccess) {
            // console.log(response.data.data)
            this.dataManagerModule = response.data.data;
          }
        },
        (error) => {
          console.log(error)
        }
      );
    },
  },
};
</script>
