<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalAddMenuset"
      id="show-modal-add-menuset"
    ></material-button>
    <material-button
      class="d-none"
      data-bs-dismiss="modal"
      data-bs-target="#modalAddMenuset"
      id="hide-modal-add-menuset"
    ></material-button>
    <div
      class="modal fade"
      id="modalAddMenuset"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ isEditMenuset ? "Edit Menuset" : "Add Menuset" }}
            </h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              @click="closeModal()"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div
                  class="w-100"
                  :class="{ 'mb-3': !fieldRequired.isMenuNameRequire }"
                >
                  <label class="fw-bold" for=""
                    >Menu Name
                    <span class="text-danger">*</span>
                  </label>
                  <material-input
                    v-model="menuSet.name"
                    type="text"
                    id="menuset_name"
                    name="menuset_name"
                    placeholder="Enter name"
                  />
                </div>
                <div class="w-100 mb-3">
                  <span
                    class="is-invalid"
                    v-if="fieldRequired.isMenuNameRequire"
                    >The field is required.</span
                  >
                </div>
                <div class="w-100 pt-1">
                  <label class="fw-bold" for="">Menu Description</label>
                  <material-textarea
                    rows="10"
                    v-model="menuSet.description"
                    id="menuset_description"
                    name="menuset_description"
                    placeholder="Enter description"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="w-100 mb-3">
                  <label class="fw-bold" for="">Menu Image</label>
                  <div
                    class="dowload-img"
                    v-show="urlImagePreview || menuSet.image"
                  >
                    <div id="preview" style="position: relative">
                      <img
                        class="image-url"
                        v-if="urlImagePreview || menuSet.image"
                        :src="urlImagePreview || menuSet.image"
                      />
                      <i
                        class="fa fa-times icon-close-sidenav-main danger"
                        v-if="urlImagePreview || menuSet.image"
                        style="
                          position: absolute;
                          color: #f44335;
                          font-size: 20px;
                          right: 5px;
                          top: 8px;
                        "
                        aria-hidden="true"
                        @click="clearImage()"
                      ></i>
                      <div class="btn-edit-img">
                        <i
                          class="fas fa-pencil-alt text-dark"
                          v-if="urlImagePreview || menuSet.image"
                          aria-hidden="true"
                          @click="uploadFile()"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div
                    class="dowload-img"
                    @click="uploadFile()"
                    v-show="!urlImagePreview && !menuSet.image"
                  >
                    <material-input
                      style="display: none"
                      type="file"
                      id="imageMenuset"
                      @change="handleFileUpload($event)"
                      accept="image/*"
                    />
                    <i class="material-icons css-icon-upload">backup</i>
                    <h6>Click here to upload an image.</h6>
                  </div>
                  <div
                    class="is-invalid d-flex align-items-center pe-2"
                    v-if="fieldRequired.isImageRequire"
                  >
                    {{ messageImageError }}
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 mt-4">
              <h5>Price List</h5>
              <div class="price-list">
                <div class="table-responsive p-0 mt-3">
                  <table class="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th>Display</th>
                        <th class="w-30">Label</th>
                        <th class="w-35">Description</th>
                        <th>Default Price</th>
                        <th>Member Price</th>
                        <th>Category Selected</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(price, index) in menuSet.priceList"
                        :key="index"
                      >
                        <td>
                          <material-checkbox
                            v-model="price.is_display"
                            :checked="price.is_display"
                            class="align-items-center checkbox-custom"
                            :isDisabled="
                              price.is_price_default || price.is_member_price
                            "
                            @change="changeDisplay($event, index)"
                          ></material-checkbox>
                        </td>
                        <td>{{ price.name }}</td>
                        <td>
                          <material-input
                            v-model="price.description"
                            type="text"
                            :id="'price_description_' + index"
                            :name="'price_description_' + index"
                          />
                        </td>
                        <td>
                          <MaterialRadio
                            v-model="price.is_price_default"
                            name="price_default"
                            value="1"
                            @change="changePriceDefault(index)"
                          >
                          </MaterialRadio>
                        </td>
                        <td>
                          <MaterialRadio
                            v-model="price.is_member_price"
                            name="member_price"
                            value="1"
                            @change="changeMemberPrice(index)"
                          >
                          </MaterialRadio>
                        </td>
                        <td class="text-center">
                          <material-checkbox
                            :checked="
                              menuSet.priceKeyCategorySelect.includes(
                                price.price_key
                              )
                            "
                            class="align-items-center checkbox-custom"
                            :isDisabled="true"
                          ></material-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex mt-3">
              <material-button
                class="mx-2 btn btn-sm btn-background"
                @click="saveMenu()"
              >
                {{ isEditMenuset ? "Save" : "Add Menuset" }}
              </material-button>
              <material-button
                class="mx-2 btn btn-sm btn-background"
                @click="closeModal()"
              >
                Cancel
              </material-button>
            </div>
          </div>
          <material-loading :active="loaderActive" />
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-9999 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MenusetService from "../services/product/menuset.service";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";

export default {
  name: "add-menuset",
  components: {
    MaterialInput,
    MaterialTextarea,
    MaterialButton,
    MaterialLoading,
    MaterialSnackbar,
    MaterialCheckbox,
    MaterialRadio,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    menusetId: {
      type: Number,
      default: 0,
    },
    isEditMenuset: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      snackbar: null,
      message: "",
      urlImagePreview: null,
      isErrorUploadImage: false,
      isImageTooSmall: false,
      messageImageError: "",
      fieldRequired: {},
      loaderActive: false,
      menuSet: {
        id: this.isEditMenuset ? this.menusetId : 0,
        name: "",
        description: "",
        image: null,
        priceList: [],
        priceKeyCategorySelect: [],
      },
    };
  },
  methods: {
    uploadFile() {
      $("#imageMenuset").click();
    },

    changeFeature(isFeature = 0) {
      if (typeof isFeature == "boolean") {
        this.category.is_feature = isFeature ? 1 : 0;
      }
    },

    clearImage() {
      this.menuset.image = null;
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
      this.isErrorUploadImage = false;
      this.isImageTooSmall = false;
    },

    handleFileUpload(event) {
      this.isImageTooSmall = false;
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        this.menuSet.image = null;
        this.urlImagePreview = null;
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          this.isErrorUploadImage = false;
          this.fieldRequired.isImageRequire = true;
          this.messageImageError = `File must be an image.`;
        } else {
          this.menuSet.image = file;
          this.urlImagePreview = URL.createObjectURL(file);
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width < 100 || height < 100) {
                this.messageImageError = `The image width ${width} x ${height} is too small.`;
                this.fieldRequired.isImageRequire = true;
                this.isErrorUploadImage = true;
                this.isImageTooSmall = true;
              } else {
                this.fieldRequired.isImageRequire = false;
                this.isErrorUploadImage = false;
                this.isImageTooSmall = false;
                this.messageImageError = "";
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        this.menuSet.image = null;
        this.urlImagePreview = null;
        this.isErrorUploadImage = false;
        this.messageImageError = "";
      }
    },

    async getPriceList() {
      var dataForm = {
        set_id: this.isEditMenuset ? this.menusetId : 0,
      };

      await MenusetService.getPriceList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.menuSet.priceList = response.data.data.price_list;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
    },

    async getMenuDetail() {
      var menuId = this.menusetId;
      if (menuId) {
        await MenusetService.menusetDetail(menuId).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.menuSet = response.data.data;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
          }
        );
      }
    },

    saveMenu() {
      if (this.processValidate()) {
        this.showLoader();
        var menuId = this.isEditMenuset ? this.menusetId : 0;
        if (menuId) {
          this.menuSet.id = menuId;
          MenusetService.update(this.menuSet).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Update MenuSet Success";
                this.setTimeOutNotification();
                this.hideLoader();
                this.$emit("reload-list-menuset");
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
                this.hideLoader();
              }
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error.response.data.result.message;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else {
          console.log(this.menuSet);
          MenusetService.store(this.menuSet).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Create MenuSet Success";
                this.setTimeOutNotification();
                this.hideLoader();
                this.$emit("reload-list-menuset");
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
                this.hideLoader();
              }
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      }
    },

    resetRequired() {
      this.fieldRequired.isMenuNameRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.menuSet.name == "" || this.menuSet.name == null) {
        this.fieldRequired.isMenuNameRequire = true;
        isValid = false;
      }

      return isValid;
    },

    closeModal() {
      this.$emit("reload-list-menuset", 'cancel');
    },

    changeDisplay(event, index) {
      if (event.target.value) {
        if (this.menuSet.priceList[index].description == "") {
          this.menuSet.priceList[index].description = "Price " + (index + 1);
        } else {
          this.menuSet.priceList[index].description = "";
        }
      }
    },

    changePriceDefault(index) {
      this.menuSet.priceList.forEach((price) => {
        price.is_price_default = 0;
      })
      this.menuSet.priceList[index].is_price_default = 1;
      this.menuSet.priceList[index].is_display = 1;
    },

    changeMemberPrice(index) {
      this.menuSet.priceList.forEach((price) => {
        price.is_member_price = 0;
      })
      this.menuSet.priceList[index].is_member_price = 1;
      this.menuSet.priceList[index].is_display = 1;
    },

    clearData() {
      this.resetRequired();
      (this.menuSet = {
        id: this.isEditMenuset ? this.menusetId : 0,
        name: "",
        description: "",
        image: null,
        priceList: [],
        priceKeyCategorySelect: [],
      }),
        (this.urlImagePreview = null);
      this.priceListDisplay = [];
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    async keyReload() {
      this.showLoader();
      this.clearData();

      if (this.isEditMenuset) {
        await this.getMenuDetail();
      }

      await this.getPriceList();
      this.hideLoader();
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}

.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}

.font-18 {
  font-size: 18px;
}

.dowload-img {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 100%;
  height: 300px;
}

.css-icon-upload {
  font-size: 45px !important;
  margin-top: 6rem;
}

.image-url {
  width: 100%;
  height: 300px;
}

.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}

.modal-dialog {
  max-width: 950px;
}
</style>
